import McUtils from '@scripts/McUtils';
import Quickview from '@scripts/ViewModels/quickview';

export const AjaxRenderingEngineEventTypes = {
    onFieldContentChanged: "AjaxRenderingEngine.onFieldContentChanged",
    onNewAreasShown: "AjaxRenderingEngine.onNewAreasShown",
    onPopupReady: "AjaxRenderingEngine.onPopupReady",
    onPopupFilterChanged: "AjaxRenderingEngine.onPopupFilterChanged",
    onAjaxRenderingLoaded: "AjaxRenderingEngine.onAjaxRenderingLoaded",
    onKnockoutRenderingCompleted: "AjaxRenderingEngine.onKnockoutRenderingCompleted",
    onPriceRefreshRequested: "AjaxRenderingEngine.onPriceRefreshRequested",
    onPushProductsIntoField: "AjaxRenderingEngine.onPushProductsIntoField",
    onClearFieldFilter: "AjaxRenderingEngine.onClearFieldFilter",
    onFieldsRefresh: "AjaxRenderingEngine.onFieldsRefresh",
    onFilterLoadRequested: "AjaxRenderingEngine.onFilterLoadRequested",
    onAddNewAreaContent:"AjaxRenderingEngine.onAddNewAreaContent"
};

export const AjaxRenderingEngine = new (function AjaxRenderingEngine($) {
    PubSub.subscribe(AjaxRenderingEngineEventTypes.onAddNewAreaContent, function (message, data) {
        jQuery('#A' + data.AreaId).html(data.Content);
        self.updateFields('#A' + data.AreaId +' .ajax-field', "#AttributeListBox", false, false, false, 0);        
    });

    var self = this;
    self.service = new WebApiService("AreaRenderer");
    self.filterService = new WebApiService("AttributeFilter");
    self.fieldRenderService = new WebApiService("FieldRenderer");
    self.statsService = new WebApiService("Stats");
    self.imageService = new WebApiService("ImageRenderer");
    self.deviceSize = "";
    self.currentSlideshow = null;
    self.allowUseOfProductCache = true;
    self.currentField = null;
    self.PreviewProduktLagerId = null;
    self.scrollEventIsRegistered = false;
    self.scrollEventFieldIsRegistered = false;
    self.scrollEventFooterIsRegistered = false;
    self.HasMorePages = false;
    self.PagingMode = 1;
    self.LoadingFromScroll = false;
    self.fieldsReadyToLoad = [];
    self.lazyLoadFieldSetupDone = false;
    self.footerObserver = null;
    self.IntersectionObserveAllowed = ('IntersectionObserver' in window);
    self.AutoScrolledPages = 0;
    self.InitialPageNumber = 1;
    self.CurrentPageNumber = 1;
    self.HighestPageNumberSeen = 1;
    self.LowestPageNumberSeen = 1;
    self.InitialLoadDone = false;
    self.subscriptionsInitialised = false;
    self.PrevPagingLoaded = false;
    self.myScrollToElement = null;
    self.currentArticleId = 0;
    self.CheckStatistics = false;
    self.ListingTypeGrid = false;
    self.lightBoxIsOpen = false;
    self.slideOverBoxIsOpen = false;
    self.attributeFilterInitiated = false;
    self.rhsQuickview = new Quickview.vm('slideover-placeholder');
    self.centerQuickview = new Quickview.vm('lightbox-placeholder');
    self.currentSearchKeyword = "";
    self.finishedLoadingFieldsAbove = false;
    self.fieldUpdateInProgress = false;
    self.placeholderDict = {};
    this.load = function () {
        if (jQuery('body.listtype-grid').length > 0) {
            self.ListingTypeGrid = true;
        }

        jQuery("div.HeaderPublishAreaContainer .load-later").each(function (index, item) {

            jQuery(item).removeClass("load-later");
            jQuery(item).addClass("load-first");
        });

        var pageParam = self.getParameterByName('pageID');
        if (pageParam.length > 0) {
            self.InitialPageNumber = parseInt(pageParam);
            self.CurrentPageNumber = self.InitialPageNumber;
            self.HighestPageNumberSeen = self.InitialPageNumber;
            self.LowestPageNumberSeen = self.InitialPageNumber;

        }
        var articleIdentifier = jQuery("div#current-article-id");
        if (articleIdentifier.length > 0) {
            self.currentArticleId = jQuery(articleIdentifier[0]).data("current-article");
        }

        self.CheckStatistics = (
            window.D4AiActive !== undefined &&
            window.D4AiActive &&
            window.D4AiRegisterStats !== undefined &&
            window.D4AiRegisterStats);

        var loadFirstParams = {
            targetKey: "div.load-first .ajax-field.not-loaded",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        var loadSecond = {
            targetKey: "div.load-later .web-pub-field[data-popup='0'] .ajax-field.loaded[data-sf='0'][data-field-is-productlist='1']",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: true,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };


        var loadLaterParams = {
            targetKey: "div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='0']:not(.menu-container div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='0'])",            
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        var loadLaterWithFilterParams = {
            targetKey: "div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='1']:not(.menu-container div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='1'])",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        var loadPopupParams = {
            targetKey: "div.ajax-field.not-loaded[data-popup='1']",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 1,
            nextPageNumber: 0
        };
        
        if (jQuery(".ProductMenu").css("order") === 1) {
            self.unhideProductMenu();//do not wait for attributes are loaded when order is 1
        }

        self.fieldsToLoad = [];
        self.fieldsToLoad.push(loadFirstParams);
        self.fieldsToLoad.push(loadSecond);
        self.fieldsToLoad.push(loadLaterParams);
        self.fieldsToLoad.push(loadLaterWithFilterParams);

        self.firstPageViewPopup = loadPopupParams;
        self.updateContentForPreloadedFields();

        self.runUpdateFields();

        self.updateSelectedAttributeListVisibility();
        PubSub.publish(AjaxRenderingEngineEventTypes.onAjaxRenderingLoaded);

        if (jQuery(window).scrollTop() === 0)
            self.finishedLoadingFieldsAbove = true;
    };

    this.getQuickview = function (placement) {
        if (placement === "rhs")
            return self.rhsQuickview;
        else return self.centerQuickview;
    };


    this.openQuickView = function (nodeid, plid, manufacturerid, placement, areaname, me) {
        if (me !== undefined) {
            //check if we can use the given placement
            var myCenteredPopup = jQuery(me).closest("#center-popup");
            if (placement == 'c' && myCenteredPopup.length > 0) {
                //the popup would normalle be shown in center, but right now the ad is already shown in center,
                //so lets use the rhs instead
                placement = 'rhs';
            }
            else if (placement === 'rhs' && jQuery(me).closest("#rhs-popup").length > 0) {
                placement = 'c';
            }
        }
        var quickview = self.getQuickview(placement);
        quickview.init({ areaName: areaname, nodeId: nodeid });
        quickview.show(plid, manufacturerid);
    };


    this.loadLightboxFields = function (options) {
        if (jQuery('body.listtype-grid').length > 0) {
            self.ListingTypeGrid = true;
        }

        var pageParam = self.getParameterByName('pageID');
        if (pageParam.length > 0) {
            self.InitialPageNumber = parseInt(pageParam);
            self.CurrentPageNumber = self.InitialPageNumber;
            self.HighestPageNumberSeen = self.InitialPageNumber;
            self.LowestPageNumberSeen = self.InitialPageNumber;

        }
        var articleIdentifier = jQuery("div#current-article-id");
        if (articleIdentifier.length > 0) {
            self.currentArticleId = jQuery(articleIdentifier[0]).data("current-article");
        }

        self.CheckStatistics = (
            window.D4AiActive != undefined &&
            window.D4AiActive &&
            window.D4AiRegisterStats != undefined &&
            window.D4AiRegisterStats);

        var loadFirstParams = {
            targetKey: "div.modal div .ajax-field.not-loaded",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        self.fieldsToLoad = [];
        self.fieldsToLoad.push(loadFirstParams);

        self.runUpdateFields();
    };

    this.loadNextPage = function () {
        self.updateFields("div.load-later .ajax-field.not-loaded[data-field-is-productlist='1']", "#AttributeListBox", true, false, false, 0, true);
    };

    this.loadPrevPage = function () {
        var loadOnlyProductListField = self.CurrentPageNumber > 1; //when scrolling upwards on the page, we we only add the productlisting field when scrolling up, until we are loading the first "page"

        var prevPageNumber = self.CurrentPageNumber - 1;
        if (prevPageNumber < 1) {
            //we're already on the first page, so we just stop processing
            return;
        }
        self.updateFields("div.load-later .ajax-field.not-loaded[data-field-is-productlist='1']", "#AttributeListBox", true, false, false, 0, prevPageNumber);
    };

    this.reloadPageCallback = function (fieldIds) {//receives array with fieldids to update
        if (self.fieldsToLoad === undefined)
            self.fieldsToLoad = [];

        if (fieldIds == undefined || fieldIds.length == 0) {
            self.updateFields(".ajax-field.not-loaded", "#AttributeListBox", false, false, false, 0, 0);
        } else {
            if (isNaN(fieldIds)) {
                for (var i = 0; i < fieldIds.length; i++) {
                    var params = {
                        targetKey: ".field-container-" + fieldIds[i] + " div.ajax-field",
                        filterTargetKey: "#AttributeListBox",
                        onlyUpdateProductList: false,
                        doNotRenderFilter: false,
                        useDummyData: false,
                        popupFilter: 0,
                        nextPageNumber: 0
                    };
                    if (jQuery(params.targetKey).length > 0)
                        self.fieldsToLoad.push(params);
                }
            } else {
                var params = {
                    targetKey: ".field-container-" + fieldIds + " div.ajax-field",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: false,
                    useDummyData: false,
                    popupFilter: 0,
                    nextPageNumber: 0
                };
                if (jQuery(params.targetKey).length > 0)
                    self.fieldsToLoad.push(params);
            }
            self.runUpdateFields();
        }
    };

    this.reloadAllLinkedFields = function () { //TODO: MOVE, move to separate publisher-only file
        var otherFields = [];
        jQuery("div.PubFieldMenu[data-include-linked-field-content='True']").each(function (index, item) {
            var fieldIdFound = jQuery(item).data("fieldid");
            otherFields.push(fieldIdFound);
        });
        self.reloadPageCallback(otherFields);
    };

    //find a single element on the page, ask the server to render it again, and then swap it out for the new one
    this.reloadSingleElement = function (nodeId, activePlid, area, fieldId, element) {  //TODO: MOVE, move to separate publisher-only file
        var item = $("#Field_" + fieldId + " div.WebPubElement[data-elementid=" + element + "]");
        if (item.length <= 0) {//implies this element has not been rendered in the field. Let us add a placholder at the end of the field, or if this field contains
            //linked content, we must add it in front of all the dynamic content
            var field = $("div.ajax-field[data-field-id=" + fieldId + "]");
            var placeholder = "<div id='plcholder" + fieldId + "' data-fieldid='" + fieldId + "' class='WebPubElement'/>";
            if (field.length > 0) {
                var linkedChildren = field.children(".WebPubElement.linked-element");
                if (linkedChildren.length > 0) {
                    $(linkedChildren[0]).before(placeholder);
                }
                else {
                    field.append(placeholder);

                }
                item = $("#plcholder" + fieldId);
            }
        }
        if (nodeId == null)
            nodeId = jQuery('#hidden-nodeid').text();

        var width = Math.round($(item).width());
        if (item.length > 0) {
            var params = {
                NodeId: nodeId,
                Width: width,
                ActivePlid: activePlid,
                AreaName: area,
                FieldId: fieldId,
                ElementId: element,
                ForceNormalMode: false,
                ForcedLayoutId: 0
            };
            self.fieldRenderService.PostAction("RenderSingleElement", params).done(function (result) {
                if (result && result.Success) {
                    $(item).replaceWith(result.Content);
                }
            });
        }
    };

    this.reloadLightboxFields = function () {
        self.updateFields(".modal-content .ajax-field.not-loaded", "#AttributeListBox", false, false, false, 0);
    };

    this.reloadFieldByListingType = function (listingtype) {
        self.reloadFromCallbackActive = true;
        self.updateFields(".ajax-field[data-listing-type=" + listingtype + "]", "#AttributeListBox", false, false, false, 0, 0);
    };

    this.reloadProductListField = function () {
        self.resetAutoScrolledPages();
        self.updateFields(".ajax-field[data-field-is-productlist='1']", "#AttributeListBox", true, false, false, 0, 0);
    };

    this.reloadInstantSearchPreview = function (plid) {
        self.PreviewProduktLagerId = plid;
        self.updateFields(".InstantSearch .d4-instant-search-loadbyajax .ajax-field",
            "",
            false,
            true,
            false,
            0,
            false);
    }


    this.setListingType = function (listingAsGrid) {
        self.ListingTypeGrid = listingAsGrid;
        if (listingAsGrid) {
            jQuery('body').addClass('.listtype-grid');
            jQuery('body').removeClass('.listtype-list');
            var fieldContentContainer = jQuery('.ajax-field.inner-layout-container[data-area-id="CenterContentDynamicProdListAlternate"]');
            var fieldContainer = fieldContentContainer.closest(".web-pub-field");
            var fieldSorters = fieldContainer.children(".FieldSorter");
            if (fieldSorters.length > 0) {
                fieldSorters.each(function (index, item) {
                    $(item).html('');
                });
            }
            jQuery('.ajax-field.inner-layout-container.not-loaded[data-area-id="CenterContentDynamicProdListAlternate"]').html('');
        } else {
            jQuery('body').addClass('.listtype-list');
            jQuery('body').removeClass('.listtype-grid');
            var fieldContentContainer = jQuery('.ajax-field.inner-layout-container[data-area-id="CenterContentDynamicAdList"]');
            var fieldContainer = fieldContentContainer.closest(".web-pub-field");
            var fieldSorters = fieldContainer.children(".FieldSorter");
            if (fieldSorters.length > 0) {
                fieldSorters.each(function (index, item) {
                    $(item).html('');
                });
            }
            jQuery('.ajax-field.inner-layout-container[data-area-id="CenterContentDynamicAdList"]').html('');
        }

        var newUrl = self.getCurrentFirstPage();
        self.CurrentPageNumber = 1;
        window.history.pushState(newUrl, '', newUrl);
    };

   
    this.SetAllowedUseOfProductCache = function (val) {
        self.allowUseOfProductCache = val;
    };

    this.reloadPageListingOnlyCallback = function (skipFilter) {
        self.reloadFromCallbackActive = true;
        var doSkipFilter = true;
        if (skipFilter !== undefined)
            doSkipFilter = skipFilter
        self.updateFields(".ajax-field[data-field-is-productlist='1']", "#AttributeListBox", true, doSkipFilter, false, 0);
    };

    this.reloadDummyFields = function (deviceSize) { //TODO: MOVE, move to separate publisher-only file

        //first, find the area that contains the dummy fields
        var container = jQuery('.layout-picker').find('.load-later');
        if (container.length > 0) {
            var nodeId = jQuery('#hidden-nodeid').text();
            var param = {
                areaName: 'LayoutPicker',
                nodeId: nodeId,
                isEdm: false,
                deviceSize: deviceSize
            };
            self.service.GetAction("RenderAreaFramework", param).done(
                function (result) {
                    if (result != undefined && result.length > 0) {
                        container.html(result);
                    }
                    self.updateFields(".ajax-field.not-loaded-dummy", "", false, false, true, 0);
                    self.scaleLayoutPicker();
                });

        }
        //then fetch the new field framework as it might have been changed
        //then update the content of the fields

    };

    this.scaleLayoutPicker = function () { //TODO: MOVE, move to separate publisher-only file
        var layoutPickerContainer = jQuery(".PublisherContainer.layout-picker");
        if (layoutPickerContainer != null) {
            var layoutSelectorFields = layoutPickerContainer.find('.web-pub-field.container.dummy');

            layoutSelectorFields.each(function (index, element) {
                var myField = jQuery(element)
                var contentHeight = myField.height();
                var contentWidth = myField.width();
                var availableWidth = 170;
                var availableHeight = 100;
                var scale = Math.min(
                    availableWidth / contentWidth,
                    availableHeight / contentHeight
                );
                //todo: find out why the height isn't always correct
                myField.css("position", "absolute");

                var translateVertical = "0 0px";
                myField.css("transform-origin", translateVertical);

                myField.css("transform", "scale(" + scale + ")");
                var layoutName = myField.data("layout-name");

                if (myField.hasClass("selected")) {
                    jQuery(myField).wrap("<div class='layout-picker-wrapper selected'></div>");
                } else {
                    jQuery(myField).wrap("<div class='layout-picker-wrapper'></div>");
                }
                jQuery(myField.parent()).before("<div class='layout-name'>" + layoutName + "</div>");
                
            });
        }
    };

    this.reloadForPublisher = function (newDeviceSize) {
        if (newDeviceSize)
            self.deviceSize = newDeviceSize;

        self.updateFields(".ajax-field.not-loaded", "#AttributeListBox", false, false, false, 0);
    };

    this.reloadFields = function (parentIdentifier) {
        self.addRemainingFieldsToDom();
        self.reloadFromCallbackActive = true;
        self.updateFields(parentIdentifier + " .ajax-field", "#AttributeListBox", false, false, false, 0, 0);
    };

    this.ChangeSortType = function (dropDownControl) {
        var cont = jQuery(dropDownControl);

        McAjaxRequest('/WebPages/Extras/SessionStateApi.aspx', 'ChangeSort', cont.val(), self.reloadPageCallback);
    };

    this.ToggleGroupVariants = function (obj) {
        try {
            var data = { group: obj.checked };
            var service = new WebApiService("AttributeFilter");
            service.GetAction("ToggleGroupVariants", data).done(function (result) {
                var newUrl = self.getCurrentFirstPage();
                self.CurrentPageNumber = 1;
                window.history.pushState(newUrl, '', newUrl);
                self.SetAllowedUseOfProductCache(false);
                self.reloadPageListingOnlyCallback(false);
            });
        } catch (e) {
            alert("Exception: " + e);
        }
    };

    this.ToggleStockMode = function (obj) {
        try {
            var data = { show: obj.checked };
            var service = new WebApiService("AttributeFilter")
            service.GetAction("ToggleShowOnlyStock", data).done(function (result) {
                self.CurrentPageNumber = 1;
                self.resetAutoScrolledPages();
                self.SetAllowedUseOfProductCache(false);
                var newUrl = self.getCurrentFirstPage();
                self.CurrentPageNumber = 1;
                window.history.pushState(newUrl, '', newUrl);
                self.reloadPageListingOnlyCallback(false);
                
            });
        } catch (e) {
            alert("Exception: " + e);
        }
    };

    this.ToggleStockModeFjernlager = function (obj) {
        try {
            var data = { show: obj.checked };
            var service = new WebApiService("AttributeFilter")
            service.GetAction("ToggleShowOnlyStockFjernlager", data).done(function (result) {
                self.CurrentPageNumber = 1;
                self.resetAutoScrolledPages();
                self.SetAllowedUseOfProductCache(false);
                var newUrl = self.getCurrentFirstPage();
                self.CurrentPageNumber = 1;
                window.history.pushState(newUrl, '', newUrl);
                self.reloadPageListingOnlyCallback(false);
            });
        } catch (e) {
            alert("Exception: " + e);
        }
    };

    this.getParameterByName = function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }


    this.addFieldToArea = function (areaName, fieldId, nodeId) {//todo: move to publisher script
        var areaContainer = $("div.area-content-container[data-area-name='" + areaName + "']");
        if (areaContainer.length > 0) {
            var width = Math.round($(areaContainer).width());
            var param = {
                AreaName: areaName,
                FieldId: fieldId,
                NodeId: nodeId,
                Width: width,
                ActivePlid: 0,
                ActiveArticleId: 0,
                AreaUniqueId: areaContainer[0].id
            };

            self.fieldRenderService.PostAction("RenderField", param).done(function (result) {
                if (result && result.Response.length > 0) {
                    if (result.AddToEnd)
                        $(areaContainer).append(result.Response);
                    else {
                        var existingFields = $(areaContainer).find(".web-pub-field");
                        if (existingFields.length > 0) {
                            $(existingFields[0]).before(result.Response);
                        }
                        else $(areaContainer).append(result.Response);
                    }
                    EnableLinksForArticlesWithBackground();
                }
            });

        }
    };
    this.replaceField = function (fieldId, nodeId, isEdm) {//todo: move to publisher script
        var areaContainer = $("#Field_" + fieldId).closest("div.area-content-container");//[data-area-name='" +areaName + "']");
        if (areaContainer.length > 0) {
            if (nodeId == undefined || nodeId == 0)
                nodeId = jQuery('#hidden-nodeid').text();
            if (isEdm == undefined)
                isEdm = false;

            var width = Math.round($(areaContainer).width());
            var param = {
                FieldId: fieldId,
                NodeId: nodeId,
                IsEdm: isEdm,
                Width: width,
                ActivePlid: 0,
                ActiveArticleId: 0,
                AreaUniqueId: areaContainer[0].id
            };

            self.fieldRenderService.PostAction("RenderField", param).done(function (result) {
                if (result && result.Response.length > 0) {
                    $("#Field_" + fieldId).replaceWith(result.Response);
                    EnableLinksForArticlesWithBackground();
                }
            });

        }
    };

   

    $.fn.scrollView = function (offset) {
        return this.each(function () {
            if (offset == undefined)
                offset = 0;

            $('html, body').animate({
                scrollTop: $(this).offset().top + offset
            }, 1000);
        });
    }

    
    var escapeRegExp = function (str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    };

    var replaceAll = function (find, replace, str) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    };

    this.RemovePageNumberFromLink = function (url) {
        var index = url.indexOf('pageID=');
        if (index >= 0) {
            var urlPartWithPageID = url.substring(index, url.length);
            var urlPartWithoutPageID = urlPartWithPageID.substring(7, urlPartWithPageID.length);
            var indexOfFirstAmpersand = urlPartWithoutPageID.indexOf('&', 1);
            if (indexOfFirstAmpersand > 0) {
                var tmp = urlPartWithoutPageID.substring(indexOfFirstAmpersand, urlPartWithoutPageID.length);
                return tmp;
            } else {
                var newUrl = replaceAll(urlPartWithPageID, '', url);
                var indexOfQuestionmark = newUrl.indexOf('?');
                if (indexOfQuestionmark > 0 && indexOfQuestionmark === (newUrl.length - 1)) {
                    newUrl = newUrl.substr(0, newUrl.length - 1);
                }
                if (newUrl.substring(newUrl.length - 1, newUrl.length) === '&')
                    newUrl = newUrl.substring(0, newUrl.length - 1);

                indexOfQuestionmark = newUrl.indexOf('?');
                if (indexOfQuestionmark > 0 && indexOfQuestionmark === (newUrl.length - 1)) {
                    newUrl = newUrl.substr(0, newUrl.length - 1);
                }

                return newUrl;
            }
        } else
            return url;
    };

    var CreateUrl = function (currentUrl, valToAdd) {
        var url = decodeURIComponent(currentUrl);

        url = self.RemovePageNumberFromLink(url);

        var filterIndex = url.indexOf('Filter=');
        if (filterIndex >= 0) {
            filterIndex += 7;
            var indexOfColonInNewValue = valToAdd.indexOf(':');

            var existingAttributeID = valToAdd.substr(0, indexOfColonInNewValue);

            var startOfExistingAttributeIDInUrl = McIndexOf(url, existingAttributeID + ':');
            if (startOfExistingAttributeIDInUrl >= 0) {
                var startOfUrl = url.substr(0, startOfExistingAttributeIDInUrl + existingAttributeID.length + 1);
                var endOfUrl = url.substr(startOfExistingAttributeIDInUrl + existingAttributeID.length + 1, url.length);
                var attributeValueID = valToAdd.substr(indexOfColonInNewValue + 1, valToAdd.length);
                return startOfUrl + attributeValueID + '^' + endOfUrl;

            } else {
                return url.substring(0, filterIndex) + valToAdd + '|' + url.substring(filterIndex, url.length);
            }
        } else {
            if (url.indexOf('?') < 0) {
                url += '?';
            } else {
                if (url.substr(url.length - 1, 1) != '&' && url.substr(url.length - 1, 1) != '?')
                    url += '&';
            }

            url += 'Filter=' + valToAdd;
            return url;
        }
    };

    this.getCurrentFirstPage = function () {
        return self.RemovePageNumberFromLink(document.location.href);
    }

    this.RemoveAttribute = function (url, parsedFilter) {
        var parsedUrl = decodeURIComponent(url);
        var newLocation = parsedUrl.replace(parsedFilter, "");
        if (newLocation.substring(newLocation.length - 1) === '|') //find pipe at the end (|)
            newLocation = newLocation.substring(0, newLocation.length - 1);

        var indexOfPipeAfterFilter = newLocation.indexOf('Filter=|'); //check if there is a remaining pipe at the beginning of the filter
        if (indexOfPipeAfterFilter > 1)
            newLocation = newLocation.replace('|', "");


        if (newLocation.substring(newLocation.length - 7) === 'Filter=')
            newLocation = newLocation.substring(0, newLocation.length - 7);

        if (newLocation.substring(newLocation.length - 1) === '&')
            newLocation = newLocation.substring(0, newLocation.length - 1);

        if (newLocation.substring(newLocation.length - 1) === '?')
            newLocation = newLocation.substring(0, newLocation.length - 1);

        newLocation = newLocation.replace('||', '|'); //If we remove an attribute from the middle, we'll have an extra pipe which can be removed.

        return newLocation;
    };

    this.RemoveAttributeValueFromLink = function (attributeID, attributeValueID) {
        var url = new String(document.location.href);
        url = decodeURIComponent(url);
        var attValRegexp = new RegExp(attributeValueID, 'ig');
        var indexBeforeAttributeValue;
        var m;
        while (m = attValRegexp.exec(url)) { //Find all occurences of the string. It might be a substring of another, so we will check if it is an exact match

            indexBeforeAttributeValue = m.index;
            var endOfStringIndex = m.index + attributeValueID.length; //index of right after string...

            if (endOfStringIndex >= url.length) { //we've reached end url, so it's OK
                break;
                //} else if (url.substr(indexBeforeAttributeValue + attributeValueID.length, 1).match(/(^|~)/i)) { //we've found the ^ character, so it's the end of the search string and thus OK
            } else {
                var substring = url.substr(indexBeforeAttributeValue + attributeValueID.length, 1);
                if (substring === "~" || substring === "^")
                    break;

            }

        }

        var newLink = '';
        if (url.substr(indexBeforeAttributeValue - 1, 1) == '^' ||
            url.substr(indexBeforeAttributeValue + attributeValueID.length, 1) == '^') {
            if (url.substr(indexBeforeAttributeValue - 1, 1) == ':') {
                newLink = url.replace(attributeValueID + '^', '');
            } else {
                if ((indexBeforeAttributeValue + attributeValueID.length) >= url.length) {
                    newLink = url.substr(0, indexBeforeAttributeValue);
                    if (newLink.substr(newLink.length - 1, 1) === "^")
                        newLink = newLink.substr(0, newLink.length - 1);
                }
                else {
                    newLink = url.replace('^' + attributeValueID, '');
                }

            }
            return newLink;
        } else {
            return self.RemoveAttribute(url, attributeID + ':' + attributeValueID);
        }
    };


    this.addValueToQueryString = function (me, attributeId, attributeValueId) {

        if (jQuery(me).hasClass("disabled"))
            return;//shouldn't allow clicks when the disabled class i set. 

        jQuery(me).addClass("attribute-spinner");
        var loc = document.location.href;
        var searchLoc = decodeURIComponent(self.getParameterByName('Filter'));
        var newLocation = "";
        var attributeValueIdExisted = false;
        if (searchLoc.length > 0) {
            var currentAttributes = searchLoc.split('|');
            for (var i = 0; i < currentAttributes.length; i++) {
                var attributeAndValues = currentAttributes[i].split(':');
                if (attributeAndValues.length >= 2) {
                    //first entry in array should be the attributeId and the second a list of attributeValues
                    if (attributeAndValues[0] === attributeId) {
                        var attributeValues = attributeAndValues[1].split('^');
                        for (var j = 0; j < attributeValues.length; j++) {
                            var currentAttributeValueId = attributeValues[j];
                            if (currentAttributeValueId === attributeValueId)
                                attributeValueIdExisted = true;
                        }
                    }
                }
            }


        }

        if (attributeValueIdExisted) {
            newLocation = self.RemoveAttributeValueFromLink(attributeId, attributeValueId);
            RemoveAttributeValueLinkFromDom(attributeValueId);

        } else {
            newLocation = encodeURI(CreateUrl(loc, attributeId + ':' + attributeValueId));
            var titleOfRemoved = me.text;
            AddDivForRemovingAttributeValue(attributeValueId, attributeId, titleOfRemoved);
        }

        newLocation = self.RemovePageNumberFromLink(newLocation);

        window.history.pushState(attributeId + ':' + attributeValueId, document.title, newLocation);

        var searchLocAfterFiltering = decodeURIComponent(self.getParameterByName('Filter'));

        self.informShopOfFilterActivation(searchLocAfterFiltering);

        self.CurrentPageNumber = 1;
        self.setInternalListOfOpenFilters();
        self.updateSelectedAttributeListVisibility();
        self.updateFields(".ajax-field", "#AttributeListBox", true, false, false, 0);
    };

    this.updateSelectedAttributeListVisibility = function () {
        var searchLoc = decodeURIComponent(self.getParameterByName('Filter'));
        if (searchLoc.length <= 0) {
            jQuery("div.SelectedAttributeBox .filter-selection-container").addClass("hide");
        }
        else {
            jQuery("div.SelectedAttributeBox .filter-selection-container").removeClass("hide");
        }
    };

    this.informShopOfFilterActivation = function (currentFilter) {
        var data = { clientHasFilter: currentFilter !== null && currentFilter !== undefined && currentFilter.length > 0 };
        self.filterService.GetAction("SetFilterActive", data).done(function (result) {

            jQuery("#cbxGroupVariants").prop("checked", result);
        });
    };

    this.setInternalListOfOpenFilters = function () {
        var openAttributes = jQuery("#AttributeListBox div.AttributeBox.show-extra-values");
        var openAttributesList = [];
        openAttributes.each(function (index, item) {
            openAttributesList.push(jQuery(item).data("attribute-id"));
        });
        self.openAttributesList = openAttributesList;

    };

    this.doScrollToNextPage = function (element) {
        self.resetAutoScrolledPages();
        return self.scrollToNextPage(element);
    };


    this.scrollToNextPage = function (element) {
        try {
            var url = element.getAttribute("data-next-page-url");
            var nextPageIndex = parseInt(element.getAttribute("data-next-page-index"));
            if (nextPageIndex > self.CurrentPageNumber) {
                //CurrentPageNumber may be off when we started on page > 1. We must reset currentpage here since we know which is to be the next page
                if (nextPageIndex > 1)
                    self.CurrentPageNumber = nextPageIndex - 1;

                var title = element.getAttribute("title");
                window.history.pushState(url, document.title + ' - ' + title, url);
                self.updateFields(".ajax-field[data-field-is-productlist=1]",
                    "#AttributeListBox",
                    true,
                    true,
                    false,
                    0,
                    nextPageIndex); //todo: check that we're actually only changing page, because otherwise we need to set the param doNotRenderFilter to false
                self.updateScroller(element);
            }
            return false; //indicate navgiation is complete
        } catch (e) {
            return true; //must navigate using the link
        }
    };

    this.scrollToPrevPage = function (element) {
        try {
            var url = element.getAttribute("data-prev-page-url");
            var nextPageIndex = element.getAttribute("data-prev-page-index");
            var prevPage = parseInt(nextPageIndex);
            if (prevPage < self.LowestPageNumberSeen)
                self.CurrentPageNumber = prevPage + 1; //it will be reduced by one within loadPrevPage

            var title = element.getAttribute("title");
            window.history.pushState(url, document.title + ' - ' + title, url);
            //todo: sjekk hvorfor jeg får side =3 eller 4 når nextPageIndex er 2, data-next-page-url blir feil også. skipper med 2 sider hver gang!

            self.loadPrevPage();
            return false; //indicate navgiation is complete
        } catch (e) {
            return true; //must navigate using the link
        }
    };

    this.navigateToPage = function (element) {
        try {
            var url = element.getAttribute("href");
            var title = element.getAttribute("title");
            window.history.pushState(url, document.title + ' - ' + title, url);
            self.updateFields(".ajax-field", "#AttributeListBox", true, true, false, 0); //todo: check that we're actually only changing page, because otherwise we need to set the param doNotRenderFilter to false
            return false; //indicate navgiation is complete
        } catch (e) {
            return true; //must navigate using the link
        }

    };

    var updateAttributeContent = function (result) {
        if (result.Success) {
            $('#' + result.ClientId).html(result.Response);
        }
    };

    this.fetchAttributeValues = function (attributeId, target, targetId, nodeId, fieldId) {
        var queryFilterParam = self.getParameterByName("Filter");
        var queryMinParam = self.getParameterByName("Min");
        var queryMaxParam = self.getParameterByName("Max");
        var pageIndex = self.getParameterByName("pageID");
        var fieldRequestFilter = {
            NodeId: nodeId,
            Url: document.location.pathname + document.location.search,
            Filter: queryFilterParam,
            MinPrice: queryMinParam,
            MaxPrice: queryMaxParam,
            SearchString: self.getParameterByName("q"),
            ClientId: targetId,
            PageIndex: pageIndex,
            FilterCacheKey: ''
        }

        self.service.PostAction("RenderAttribute", { AttributeId: attributeId, FieldId: fieldId, RequestFilter: fieldRequestFilter }).done(
            function (result) {
                if (result != null) {
                    updateAttributeContent(result);
                }
            }
        );
    };

    this.updateScroller = function (element) {

        self.service.GetAction("GetNextPageUrl", { currentUrl: jQuery(element).data("next-page-url"), currentIndex: self.CurrentPageNumber }).done(
            function (result) {
                if (result != null) {
                    self.CurrentPageNumber = self.CurrentPageNumber + 1;
                    if (self.CurrentPageNumber > self.HighestPageNumberSeen)
                        self.HighestPageNumberSeen = self.CurrentPageNumber;

                    jQuery(element).data("next-page-url", result);
                    var nextIndex = parseInt(jQuery(element).data("next-page-index"));
                    jQuery(element).data("next-page-index", nextIndex + 1);

                }
            }
        );
    };

    this.hidePaging = function () {
        var pagingTag = jQuery('.field-paging-container');
        jQuery(pagingTag).addClass('hidden');
    }
    this.showPagingContainer = function () {
        var pagingTag = jQuery('.field-paging-container');
        jQuery(pagingTag).removeClass('hidden');
    }

    this.showPageLoadingIcon = function () {
        var pagingTag = jQuery('.field-paging-next');
        jQuery(pagingTag).addClass('hidden');
        var loadingTag = jQuery('.field-paging-loading');
        jQuery(loadingTag).removeClass('hidden');
    };

    this.hidePageLoadingIcon = function () {
        var pagingTag = jQuery('.field-paging-next');
        jQuery(pagingTag).removeClass('hidden');
        var loadingTag = jQuery('.field-paging-loading');
        jQuery(loadingTag).addClass('hidden');
    };

    this.runUpdateFields = function () {
        if (self.fieldsToLoad != undefined && self.fieldsToLoad.length > 0) {
            var param = self.fieldsToLoad.shift();
            self.updateFields(param.targetKey,
                param.filterTargetKey,
                param.onlyUpdateProductList,
                param.doNotRenderFilter,
                param.useDummyData,
                param.popupFilter,
                param.nextPageNumber);
        }
    };


    this.refreshPriceCache = function () {
        self.service.PostAction("ClearPriceCaches").done(function (result) {

        });
    };

    this.updateFields = function (targetKey, filterTargetKey, onlyUpdateProductList, doNotRenderFilter, useDummyData, popupFilter, nextPageNumber) {
        //todo: vis venteikon
        var fieldsToLoad = $(targetKey);
        var fieldsArray = [];
        var queryFilterParam = self.getParameterByName("Filter");
        var queryMinParam = self.getParameterByName("Min");
        var queryMaxParam = self.getParameterByName("Max");
        var pageIndex = self.getParameterByName("pageID");
        var otherContactId = self.getParameterByName("kontaktid");
        var getNextPage = !!nextPageNumber;
        var getPrevPage = false;
        var isInsidePopup = targetKey.indexOf(".modal") > -1;

        if (nextPageNumber !== undefined && onlyUpdateProductList) {
            pageIndex = nextPageNumber;
        }
        if (getNextPage) {
            pageIndex = nextPageNumber;

            if (onlyUpdateProductList && nextPageNumber < self.CurrentPageNumber) {
                getPrevPage = true;
            }
            self.showPageLoadingIcon();
        }
        if (self.deviceSize == "")
            self.deviceSize = mcWeb.responsive.getScreenSize();
        var isForInstantSearch = targetKey.indexOf(".d4-instant-search-loadbyajax") >= 0;

        var fieldRequestFilter = {};
        var requestFilterAdded = false;
        var sendFilterOnly = false;
        for (var i = 0; i < fieldsToLoad.length; i++) {
            var current = $(fieldsToLoad[i]);
            var isProductListField = false;
            var areaid = current.data("area-id");

            if (onlyUpdateProductList) {
                if (areaid !== "CenterContentDynamicAdList" && areaid !== "CenterContentDynamicProdListAlternate")
                    continue;//only need to update productlist, lets skip all other fields

                sendFilterOnly = $(current).hasClass("loaded") && !getNextPage && !self.InitialLoadDone;
                if (pageIndex === 0)
                    pageIndex = self.InitialPageNumber;
            }

            if (current.parents(".stop-ajax-rendering").length > 0)
                continue;//this area is not yet ready to be rendered, it is known to be hidden.

            if (areaid === "CenterContentDynamicAdList" || areaid === "CenterContentDynamicProdListAlternate") {
                if (self.ListingTypeGrid && areaid === "CenterContentDynamicProdListAlternate") {
                    current.html('');//remove spinner for fields with no content
                    continue;//only one of the areas should be updated, so skip the area that isn't used for the current listing type
                }
                else if (!self.ListingTypeGrid && areaid === "CenterContentDynamicAdList") {
                    current.html('');//remove spinner for fields with no content
                    continue;
                }

                isProductListField = true;
                var filterContainer = $(filterTargetKey);
                if (filterContainer === undefined || filterContainer === null)
                    return; //filter not meant to be visible

                filterContainer = $(filterContainer);
                var filterClientId = "";
                if (filterContainer != undefined &&
                    filterContainer != null &&
                    filterContainer.length > 0 &&
                    filterContainer[0] != null) {
                    filterClientId = filterContainer[0].id;
                } else doNotRenderFilter = true;

                var cacheKey = "";
                if (self.allowUseOfProductCache && pageIndex <= 1) {
                    //if (!onlyUpdateProductList || doNotRenderFilter) //loading the page, data will have been cached when determining the visibility of fields
                    cacheKey = current.attr("data-cachekey");
                }

                fieldRequestFilter = {
                    NodeId: current.data("node-id"),
                    Url: document.location.pathname + document.location.search,
                    Filter: queryFilterParam,
                    MinPrice: queryMinParam,
                    MaxPrice: queryMaxParam,
                    SearchString: self.getParameterByName("q"),
                    ClientId: filterClientId,
                    PageIndex: pageIndex,
                    FilterCacheKey: cacheKey,
                    FilterIsJson: false,
                    OpenAttributes: self.openAttributesList,
                    OtherContactId: otherContactId
                };
                requestFilterAdded = true;
            }
            else if (jQuery(current).data("sf") === 1) {
                fieldRequestFilter = {
                    NodeId: current.data("node-id"),
                    Url: document.location.pathname + document.location.search,
                    Filter: JSON.stringify(self.currentPopupFilter),//todo: keep a filter after # in the url?
                    MinPrice: null,//queryMinParam, get from filter after # in url
                    MaxPrice: null,//queryMaxParam,get from filter after # in url
                    SearchString: '',
                    ClientId: 'secondary-filter',
                    PageIndex: 0,
                    FilterCacheKey: '',
                    FilterIsJson: self.currentPopupFilter !== undefined && self.currentPopupFilter !== null,
                    OpenAttributes: null,
                    OtherContactId: otherContactId
                };
                requestFilterAdded = true;
            }
            else if (!requestFilterAdded) {
                fieldRequestFilter = {
                    NodeId: current.data("node-id"),
                    Url: document.location.pathname + document.location.search,
                    Filter: '',//todo: keep a filter after # in the url?
                    MinPrice: null,//queryMinParam, get from filter after # in url
                    MaxPrice: null,//queryMaxParam,get from filter after # in url
                    SearchString: self.currentSearchKeyword,
                    ClientId: '',
                    PageIndex: 0,
                    FilterCacheKey: '',
                    FilterIsJson: false,
                    OpenAttributes: null,
                    OtherContactId: otherContactId
                };
                if (current.data("filter-id") != null && current.data("filter-id") != undefined && current.data("filter-id").length > 0) {
                    fieldRequestFilter.ClientId = current.data("filter-id");
                }
                requestFilterAdded = true;
            }

            if (!onlyUpdateProductList || isProductListField) {
                var width = self.getFieldWidth(current, isForInstantSearch);
                var myPlids = null;
                var myPlidsString = current.data('plids');
                if (myPlidsString != undefined && myPlidsString != null)
                    myPlids = myPlidsString;

                var myPlid = current.data('plid');
                if (self.PreviewProduktLagerId != null) {
                    myPlid = self.PreviewProduktLagerId;
                }
                if (targetKey != "div.load-later .ajax-field.not-loaded[data-sf='0']" || width > 0) {
                    var fieldData = {
                        Width: width,
                        AreaName: current.data("area-id"),
                        FieldId: current.data("field-id"),
                        NodeId: current.data("node-id"),
                        ClientId: current[0].id,
                        UseSpecificLayoutId: current.data("use-specific-layout"),
                        LayoutId: current.data('layoutid'),
                        ManufacturerId: current.data('manufacturerid'),
                        VariantSorting: current.data('variant-sorting'),
                        Plid: myPlid,
                        PlidList: myPlids,
                        SendFilterOnly: sendFilterOnly
                    };
                    fieldsArray.push(fieldData);
                }
                else {
                    current.find("i.icon-spinner").remove();
                }
            }
        }
        var skipSorter = getPrevPage;
        self.PreviewProduktLagerId = null;
        if (fieldsArray.length > 0) {
            self.service.PostAction("RenderFields", {
                Data: fieldsArray, RequestFilter: fieldRequestFilter,
                SkipFilterRendering: doNotRenderFilter || getNextPage, UseDummyData: useDummyData, DeviceSize: self.deviceSize, GetElementsOnly: getNextPage, SkipSorter: skipSorter,
                CurrentArticle: self.currentArticleId, PopupFilter: popupFilter
            }).done(
                function (result) {
                    if (result != null) {
                        if (isForInstantSearch || self.reloadFromCallbackActive || isInsidePopup || pageIndex > 1)
                            result.AllowDeferred = false;
                        if (getPrevPage) {
                            result.Prepend = true;
                        } else {
                            result.Prepend = false;
                        }

                        self.updateFieldContent(result);

                        if (result.EmptyFields !== null && result.EmptyFields.length > 0) {
                            for (var i = 0; i < result.EmptyFields.length; i++) {
                                $("#" + result.EmptyFields[i]).html('');//remove spinner for fields with no content
                            }
                        }
                        self.reloadFromCallbackActive = false;
                        if (getNextPage) {
                            pageIndex = nextPageNumber;
                            self.hidePageLoadingIcon();
                        }

                        if (targetKey.indexOf("div.load-later") >= 0) {
                            if (self.InitialLoadDone == false && window.D4DeferredScripts != undefined) {
                                setTimeout(function () {
                                    var parser = new DOMParser;
                                    var dom = parser.parseFromString(
                                        '<!doctype html><body>' + window.D4DeferredScripts,
                                        'text/html');
                                    var decodedString = dom.body.textContent;

                                    if (window.D4SingleScript) {
                                        //we only have the path; let's use jquery to load the script
                                        jQuery.getScript(decodedString,
                                            function (data, textStatus, jqxhr) {
                                                //console.log(data); // Data returned
                                                console.log(textStatus); // Success
                                                console.log(jqxhr.status); // 200
                                                console.log("Load was performed.");
                                            });
                                    } else {
                                        jQuery("body").append(decodedString);
                                    }

                                },
                                    5);
                            }
                            self.setupSubscriptions();
                            setTimeout(self.postStatistics, 250);
                        }
                    }

                    if (self.fieldsToLoad != undefined && self.fieldsToLoad.length > 0) {
                        self.runUpdateFields();
                    } else {

                        if (!self.InitialLoadDone) {
                            self.setupSubscriptions();
                        }
                    }
                    if (isInsidePopup) {
                        PubSub.publish(mcWeb.lightbox.events.contentChanged);
                    }

                }
            );
        }
        else if (!self.InitialLoadDone) {
            if (self.fieldsToLoad !== undefined && self.fieldsToLoad.length > 0) {
                self.runUpdateFields();
            }
            else {
                if (!self.InitialLoadDone) {
                    self.setupSubscriptions();
                }
            }
        }

    };

    self.getFieldWidth = function (current, isForInstantSearch) {
        var width = current.width();
        if (width == 0 && isForInstantSearch) {
            if (self.deviceSize == "sm")
                width = 200;
        }
        else if (width == 0) {
            var fieldParents = jQuery(current).parents(".web-pub-field.container, .web-pub-field.container-fluid");
            for (var parentIndex = 0; parentIndex < fieldParents.length; parentIndex++) {
                if (fieldParents[parentIndex] != null) {
                    var currentParent = jQuery(fieldParents[parentIndex]);
                    var tmpWidth = currentParent.width();
                    if (tmpWidth > 0) {
                        width = tmpWidth;
                        break;
                    }
                }
            }
        }
        if (width <= 0) {
            var $current = $(current);
            //if this is a field that will open in a popup, and the popup is not visible yet, the width must be set by the size of the lightbox
            if ($current.data("popup") > 0) {
                var lightboxLocator = "#center-popup";
                if ($current.data("popup-plc") == 1) {
                    lightboxLocator = "#rhs-popup";
                }
                width = $(lightboxLocator).width();
            }
        }

        return width;
    };

    self.setupSubscriptions = function () {
        if (self.subscriptionsInitialised)
            return; //we've done this already
        self.subscriptionsInitialised = true;

        self.disableDoubleClick("ad-buy-button");
        PubSub.subscribe(AjaxRenderingEngineEventTypes.onFieldContentChanged,
            function (data, message) {
                self.disableDoubleClick("ad-buy-button");
                if ($("body.publish-mode").length > 0) {
                    mcWeb.publisher.checkElementsMarkedInSessionStorage();
                }
            });
       
        
        PubSub.subscribe(AjaxRenderingEngineEventTypes.onPriceRefreshRequested,
            function (message, data) {
                if (data !== undefined && data !== null) {
                    self.updateContentForPreloadedFields(data.Target,false);
                }
                else self.updateContentForPreloadedFields("div.ajax-field", false);
            });

        PubSub.subscribe(mcWeb.cartsmall.events.onShowNewItemInCartText,
            function (message, data) {
                if (data.favouriteCart != undefined && data.favouriteCart)
                    return; //we don't show this popup when products were added favourite carts

                var lightboxModal = jQuery("#center-popup").data('bs.modal');
                if (lightboxModal !== null && lightboxModal !== undefined && lightboxModal.isShown)
                    return;
                var loadPopupParams2 = {
                    targetKey: "div.ajax-field.not-loaded[data-popup=6]",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 6,
                    nextPageNumber: 0
                };
                self.fieldsToLoad.push(loadPopupParams2);
                self.runUpdateFields();

                var loadPopupParams = {
                    targetKey: "div.ajax-field.not-loaded[data-popup=2]",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 2,
                    nextPageNumber: 0
                };
                self.fieldsToLoad.push(loadPopupParams);
                self.runUpdateFields();

            });

        PubSub.subscribe(mcWeb.cart.events.onPopupForProductAssembly,
            function (message, data) {
                var p = {
                    targetKey: "div.ajax-field.not-loaded[data-popup=8]",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 8,
                    nextPageNumber: 0
                };
                self.updateFields(p.targetKey, p.filterTargetKey, p.onlyUpdateProductList, p.doNotRenderFilter, p.useDummyData, p.popupFilter, p.nextPageNumber);
            });

        PubSub.subscribe(AjaxRenderingEngineEventTypes.onPopupFilterChanged,
            function (message, data) {
                // data contains attribute, attributevalue and the sender
                //use this to find the nearest field that should be filtered by the attribute
                self.currentPopupFilter = data.FullFilter;

                var nextF = jQuery(data.Sender).parent().parent().next();
                if (nextF.length > 0) {

                    var nextField = jQuery(nextF).find('.ajax-field');
                    if (nextField.length > 0) {
                        //we should now have stored the current filter in self.currentPopupFilter, so now we can ask
                        //for the field content to be updated
                        var targetFieldId = nextField[0].id;
                        var targetFieldPopupRule = jQuery(nextField).data("popup");

                        var loadPopupParams = {
                            targetKey: "#" + targetFieldId,
                            filterTargetKey: "AttributeListBox",
                            onlyUpdateProductList: false,
                            doNotRenderFilter: false,
                            useDummyData: false,
                            popupFilter: targetFieldPopupRule,
                            nextPageNumber: 0
                        };
                        self.fieldsToLoad.push(loadPopupParams);
                        self.runUpdateFields();
                    }
                }

            });

        PubSub.subscribe(AjaxRenderingEngineEventTypes.onPushProductsIntoField,
            function (message, data) {
                var fieldNode = jQuery(".ajax-field[data-field-id=" + data.FieldId + "]");
                if (fieldNode.length === 0) {
                    console.log("node not found");
                    return;
                }
                if (data.Plids !== undefined && data.Plids !== null && data.Plids.length > 0) {
                    jQuery(fieldNode[0]).data("plids", data.Plids);
                    jQuery(fieldNode[0]).data("filter-id", "secondary-filter");

                    var param = {
                        targetKey: "#" + jQuery(fieldNode[0]).attr("id"),
                        filterTargetKey: "secondary-filter",
                        onlyUpdateProductList: false,
                        doNotRenderFilter: false,
                        useDummyData: false,
                        popupFilter: null,
                        nextPageNumber: 0
                    };

                    if (data.ExtraData !== undefined && data.ExtraData != null) {
                        self.placeholderDict["extraData" + data.FieldId] = data.ExtraData;                        
                    }

                    
                    self.updateFields(param.targetKey,
                        param.filterTargetKey,
                        param.onlyUpdateProductList,
                        param.doNotRenderFilter,
                        param.useDummyData,
                        param.popupFilter,
                        param.nextPageNumber);
                }
                else {
                    jQuery(fieldNode[0]).attr("data-Plids", data.Plids);
                    jQuery(fieldNode[0]).attr("data-filter-id", "secondary-filter");
                    jQuery(fieldNode).html('');
                    jQuery(".secondary-filter").html('');
                }

            });

        mcWeb.ajaxPopup.load();//sets up subscriptions for event-controlled pop-ups
        self.checkForPopupAfterProductInfoVisit();
        self.checkForPopupFirstPageView();
        (function () {

            if (typeof window.CustomEvent === "function") return false;

            function CustomEvent(event, params) {
                params = params || { bubbles: false, cancelable: false, detail: null };
                var evt = document.createEvent('CustomEvent');
                evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
                return evt;
            }

            window.CustomEvent = CustomEvent;
        })();

        const formHead = document.querySelector('head');
        formHead.dispatchEvent(new CustomEvent("readyForGtm", { bubbles: true, cancelable: false, detail: null }));
        self.InitialLoadDone = true;
        //todo: her trenger vi starte å prosessere en ny kø med jobber med javascript kall som legges inn av HTMLen som kommer publisert i f.eks. artikler.

    };

    self.checkForPopupFirstPageView = function () {
        if (!!self.firstPageViewPopup) {
            if (jQuery("body.publish-mode").length <= 0) {

                var param = self.firstPageViewPopup;
                self.firstPageViewPopup = null;
                self.updateFields(param.targetKey,
                    param.filterTargetKey,
                    param.onlyUpdateProductList,
                    param.doNotRenderFilter,
                    param.useDummyData,
                    param.popupFilter,
                    param.nextPageNumber);
            }
            else {
                self.firstPageViewPopup = null;
            }

        }

    }
    self.disableDoubleClick = function (classToDisable) {
        jQuery('.' + classToDisable).dblclick(function (e) {
            e.preventDefault();
        });
    };

    self.checkForPopupAfterProductInfoVisit = function () {
        var param = {
            NodeId: jQuery("#hidden-nodeid").text()
        };
        self.statsService.PostAction("CheckForPopupAfterProductVisit", param).done(function (result) {
            if (self.fieldsToLoad == undefined)
                self.fieldsToLoad = [];
            if (result) {
                var loadPopupParams = {
                    targetKey: "div.ajax-field.not-loaded[data-popup='4']",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 4,
                    nextPageNumber: 0
                };

                self.updateFields(loadPopupParams.targetKey,
                    loadPopupParams.filterTargetKey,
                    loadPopupParams.onlyUpdateProductList,
                    loadPopupParams.doNotRenderFilter,
                    loadPopupParams.useDummyData,
                    loadPopupParams.popupFilter,
                    loadPopupParams.nextPageNumber);

            } else {
                var loadPopupParams = {
                    targetKey: "div.ajax-field.not-loaded[data-popup='5']",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 5,
                    nextPageNumber: 0
                };

                self.updateFields(loadPopupParams.targetKey,
                    loadPopupParams.filterTargetKey,
                    loadPopupParams.onlyUpdateProductList,
                    loadPopupParams.doNotRenderFilter,
                    loadPopupParams.useDummyData,
                    loadPopupParams.popupFilter,
                    loadPopupParams.nextPageNumber);
            }
        });
    };

    self.addFieldSorterToPage = function (data, $targetField, fieldData) {
        if (data.FieldSorterResponse !== null &&
            data.FieldSorterResponse.length > 0 && !fieldData.Prepend) {//when we prepend the page content, we don't need to update the "go to next page" link
            var myFieldContainer = $($targetField.parent()).parent();
            // fjern eksisterende paging før vi legger til den nye
            var oldFieldSorter = $(myFieldContainer).find(".FieldSorter");
            var oldFieldSorterCount = oldFieldSorter.length;
            oldFieldSorter.remove();
            if (fieldData.PagingMode <= 1 || $("#mmenu").length > 0 || $("body.mc-filter-left").length > 0) {
                $(myFieldContainer).prepend(data.FieldSorterResponse);
            }

            if (data.FieldSorter2Response !== null &&
                data.FieldSorter2Response.length > 0)
                $(myFieldContainer).append(data.FieldSorter2Response);

            var btn = $('#filter-btn');
            btn.show();

            //if(oldFieldSorterCount > 0)
            PubSub.publish(window.Menu.prototype.events.onAddNewFilterButton, null);
        }
    };
    self.addFieldContentToPage = function (fieldData, targetField, data) {
        self.bottomImageToLoad = null;
        if (targetField != undefined && !data.added) {
            var $targetField = $(targetField);

            data.added = true;
            self.addFieldSorterToPage(data, $targetField, fieldData);
            if (fieldData.FilterCacheKey !== null)//todo: check how to make this null when page index is not 1
                $targetField.attr("data-cachekey", fieldData.FilterCacheKey);//update cache key. Especially useful when filtering is being applied, to allow quick reload of previously used filters

            self.registerStatistics(data);

            if (data.LoadFilter) {
                this.updateFilterContent(data);

            }
            if (!fieldData.AddToExistingField) {
                if (!data.SendFilterOnly) {//we called this only to get the attribute filter when SendFilterOnly is true
                    $targetField.html(data.Response);
                    self.ensureCorrectImageSizesForField(targetField, data);
                    self.applyCustomerSpecifics(targetField, data);
                }

                var imageList = $targetField.find(".AddProductImage img");
                var lastImage = imageList.last();
                if (lastImage != null && lastImage.length > 0) {
                    if (self.containsLoadableImage(lastImage))
                        self.bottomImageToLoad = lastImage;
                }
                else if (imageList.length <= 0) {
                    //No images found by looking for them in ads, so check for articles...                               
                    if (self.bottomImageToLoad === null || self.bottomImageToLoad === undefined) {
                        //we might only have articles in the field, let's look for one...
                        var backgroundImgItems = $targetField.find("div.ArticleWithBackground.D4Standard .inner-content");
                        if (backgroundImgItems.length > 0) {
                            var bgItem = backgroundImgItems.last();
                            if (bgItem != null) {
                                if (self.containsLoadableImage(bgItem))
                                    self.bottomImageToLoad = bgItem;
                            }
                        }
                    }
                }

                PubSub.publish(AjaxRenderingEngineEventTypes.onFieldContentChanged, data);
            } else {
                if (fieldData.Prepend) {
                    $targetField.prepend(data.Response);
                    PubSub.publish(AjaxRenderingEngineEventTypes.onFieldContentChanged, data);
                } else {
                    $targetField.append(data.Response);
                    PubSub.publish(AjaxRenderingEngineEventTypes.onFieldContentChanged, data);
                }
                self.ensureCorrectImageSizesForField(targetField, data);
                self.applyCustomerSpecifics(targetField, data);
            }
            self.initialiseAdLinks();
            if (fieldData.InitializeRoyalSlider)
                InitializeRoyalSliderInAds("#" + data.ClientId + " .variant-slider");



            if (!data.SendFilterOnly && (data.Response === null || data.Response.length === 0)) {

                if ((data.AreaName === "CenterContentDynamicAdList"
                    || data.AreaName === "CenterContentDynamicProdListAlternate")
                    && data.IsNoHitSearch) {
                    jQuery(".search-no-result").show();
                    if (data.ShowInStockOnly || data.ShowInRemoteStockOnly) {
                        jQuery(".search-no-result-not-instock").show();
                        //hide the lhs filterbutton
                    }
                    else {
                        jQuery(".search-no-result-not-instock").hide();
                    }
                    jQuery("div.container-2016.has-filters").hide();

                }
                if (data.AreaName === "CenterContentDynamicAdList") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicAdList").hide();
                } else if (data.AreaName === "CenterContentDynamicProdListAlternate") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicProdListAlternate").hide();
                }
            } else {
                if ((data.AreaName === "CenterContentDynamicAdList"
                    || data.AreaName === "CenterContentDynamicProdListAlternate")
                    && data.IsProductListing) {
                    jQuery("body:not(.publish-mode) .search-no-result").hide();
                }
                if (data.AreaName === "CenterContentDynamicAdList") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicAdList").show();
                } else if (data.AreaName === "CenterContentDynamicProdListAlternate") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicProdListAlternate").show();
                }
            }
            if (fieldData.IsDummyField) {
                $targetField.click(self.selectLayoutClick);
                $targetField.addClass("wired" + data.ClientId);
            }

            if (data.LayoutCssClass != null && data.LayoutCssClass != undefined) {
                var fieldToUpdateLayout = $targetField.closest('.web-pub-field:not(.' + data.LayoutCssClass + ")");
                $(fieldToUpdateLayout).removeClass(function (index, className) {
                    return (className.match(/(^|\s)layout-\S+/g) || []).join(' ');
                });
                $(fieldToUpdateLayout).addClass(data.LayoutCssClass);

            }
        }
    };

    this.containsLoadableImage = function (currImg) {
        if (currImg.length !== undefined && currImg.length > 0) {
            for (var i = 0; i < currImg.length; i++) {
                if (self.containsLoadableImage(currImg[i]))
                    return true;
            }
            return false;
        }

        if (currImg != null && currImg.src != undefined && currImg.src != null)
            return true;
        else if ($(currImg).hasClass("inner-content")) {
            var bg = $(currImg).css('background-image').trim();
            if (bg.length > 0 && bg !== "none") {
                return true;
            }
        }

        return false;
    };

    this.initializeSlideShow = function ($targetField, slideshowmodel) {
        if (slideshowmodel == null || slideshowmodel == undefined)
            return;

        var myParent = $targetField.closest('.web-pub-field');
        if (!$(myParent).hasClass('NoSlide'))
            return;

        $(myParent).addClass('around_slides');
        $(myParent).removeClass('NoSlide');
        $targetField.addClass('royalSlider');

        mcSlide.InitSlideshow(myParent[0].id,
            slideshowmodel.Play,
            slideshowmodel.Pause,
            slideshowmodel.HoverPause,
            slideshowmodel.GenerateNextPrev,
            slideshowmodel.GeneratePagination,
            slideshowmodel.ArrowsNavAutoHide,
            slideshowmodel.FadeSpeed,
            slideshowmodel.SlideSpeed,
            slideshowmodel.Effect,
            slideshowmodel.CrossFade,
            slideshowmodel.Orientation,
            slideshowmodel.ScaleType,
            slideshowmodel.Randomize,
            slideshowmodel.Css,
            slideshowmodel.IsMobileDomain,
            slideshowmodel.Width,
            slideshowmodel.Height,
            true);
    };

    this.resolveImagesOnImageLoaded = function (image) {
        return new Promise((resolve, reject) => {
            if (image.complete) {
                resolve();
            } else {
                image.onload = () => {
                    resolve();
                };
            }
        });
    };

    this.getArrayOfArrays = function (inputArray, itemsPerArray) {
        var arrayOfArrays = [];
        for (var i = 0; i < inputArray.length; i += itemsPerArray) {
            arrayOfArrays.push(inputArray.slice(i, i + itemsPerArray));
        }
        return arrayOfArrays;
    };

    this.getSlideshowModel = function($targetField, data){
        if (data != null && data.SlideShowModel != undefined && data.SlideShowModel != null) 
            return  data.SlideShowModel;
        
        if (!$targetField.hasClass("slides_container"))
            return null;

        //may store slideshow in the container field
        var parentWithSlideInfo = $targetField.parents(".web-pub-field[data-slideshow]");
        if (parentWithSlideInfo.length <= 0)
            return null;

        return parentWithSlideInfo.data("slideshow");
    };

    this.ensureCorrectImageSizes = function (images, $targetField, data, lazyImg, initialiseSlideshows) {
        
        //Note! Array.from for IE has been added to the McWeb4-standard.js file.
        //when images are either not in need of resizing, or the new images have been fetched, we try to set up any slideshow for the field.

        if ((images === null || images === undefined || images.length <= 0) && initialiseSlideshows) {
            //no need to load images, so let's just see if slideshow must be applied
            var slidemodel = self.getSlideshowModel($targetField, data);
            if (slidemodel !== null) {
                self.initializeSlideShow($targetField, slidemodel);
            }
            
            return;
        }
        var nodeId = jQuery('#hidden-nodeid').text();
        var itemsPerArray = 8;
        if (data != null && (data.ImgModuleVer >= 5 || data.SlideShowModel != null && data.SlideShowModel != undefined ))
            itemsPerArray = images.length; // must keep it down to 1 request for images when dealing with slideshow, or when ImageModulVersion >= 5

     
        var imageLoadedPromises = Array.from(images).map(self.resolveImagesOnImageLoaded);
        Promise.all(imageLoadedPromises)
            .then(() => {
                
                var imageList = images.toArray().map(i => self.getParamForImage(i, lazyImg, nodeId)).filter(y => y !== null);

                var arrayOfImageArrays = self.getArrayOfArrays(imageList, itemsPerArray);

                var requestForImagesSent = false;
                for (var arrayId = 0; arrayId < arrayOfImageArrays.length; arrayId++) {
                    var items = arrayOfImageArrays[arrayId];
                    requestForImagesSent = true;
                    self.imageService.PostAction("ListImageTagsAsync", { Items: items }).done(function (result) {
                        var delaySlideshow = false;
                        if (result && result.items && result.items.length > 0) {
                            var items = result.items;
                            for (var i = 0; i < items.length; i++) {
                                var item = items[i];
                                if (!item.success)
                                    continue;
                                delaySlideshow = true;//slideshow must wait for these new images have loaded

                                if (item.tag !== "")
                                    jQuery('#' + item.targetId).replaceWith(item.tag);
                                else {
                                    var image = jQuery('#' + item.targetId + "  img.d4-lazy-thumb");
                                    image.removeClass("d4-lazy-thumb");
                                    image.addClass("d4-prod-thumb");
                                }
                            }
                        }
                        if (!initialiseSlideshows)
                            return;

                        var slidemodel = self.getSlideshowModel($targetField, data);

                        if (slidemodel === null)
                            return;

                        if (delaySlideshow) {//need to await the new images have been loaded before we set up slideshow
                            var newImages = $targetField.find("img.d4-prod-thumb");
                            self.initializeSlideShowAfterImagesLoaded($targetField, newImages, slidemodel);
                        }
                        else self.initializeSlideShow($targetField, slidemodel);
                    });
                }
                if (!initialiseSlideshows)
                    return;

                var slidemodel = self.getSlideshowModel($targetField, data);
                if (!requestForImagesSent && slidemodel !== null) {
                    self.initializeSlideShow($targetField, slidemodel);
                }
            });
       
    };

    this.getParamForImage = function (image, lazyImg, nodeId) {
        var img = jQuery(image);
        var parent = img.parent();
        if (parent === null || parent === undefined || parent.length <= 0)
            return null;

        var containerTagType = parent[0].tagName.toLowerCase();
        var includeLink = containerTagType === "a";
        var parentWidth = parent.width();
        var parentHeight = parent.height();
        var myWidth = img.data("width");
        var myHeight = img.data("height");
        var usesAltImg = img.data("altImg") === 1;
        var imgStatus = img.data("status");
        var elementId = img.data("elementid");
        var css = img.data("css");
        var autoscale = img.data("autoscale");        

        if (usesAltImg || (!lazyImg && (imgStatus == undefined || imgStatus.toUpperCase() !== "OK")))
            return null;

        if (!lazyImg  && myWidth / parentWidth >= 0.75)
            return null;//if lazyImg, no image loaded so we must allways load a new image

        var aspect = 1;
        if (parentWidth > 0 && parentHeight > 0)
            aspect = parentWidth / parentHeight;
        else if (myWidth > 0 && myHeight > 0)
            aspect = myWidth / myHeight;//applies when parent not visible and hence no parent size available

        if (aspect < 0.1 || aspect > 2) {
            //lazy loading in use, must use different aspect calculation
            aspect = myWidth / myHeight;
        }
        var myId = img[0].id;
        if (myId === "")
            myId = parent[0].id;

        var item = {
            TargetId: myId,
            Plid: img.data("plid"),
            Index: img.data("imageIndex"),
            Width: parentWidth,
            Aspect: aspect,
            IncludeLink: includeLink,
            UseSecondaryImage: img.data("hassecondaryimg") === 1,
            IncludeNotFound: lazyImg,
            ElementId: 0,
            Css: '',
            AutoScale: autoscale,
            NodeId: nodeId
        };
        if (elementId !== undefined && elementId !== null)
            item.ElementId = elementId;
        if (css !== undefined && css !== null)
            item.Css = css;

        return item;
    };

    this.initializeSlideShowAfterImagesLoaded = function($targetField, images, slidemodel){
        if(images !== null && images !== undefined && images.length > 0){
            var imageLoadedPromises = Array.from(images).map(self.resolveImagesOnImageLoaded);
            Promise.all(imageLoadedPromises)    
            .then( () => {
                if (slidemodel != undefined && slidemodel != null){
                    self.initializeSlideShow($targetField, slidemodel);
                    }
                });
            }
        };

    this.initialiseAdLinks = function () {
        setTimeout(function () {
            if (window.D4LinkArraySetup != undefined && window.D4LinkArraySetup != null) {
                for (var i = 0; i < window.D4LinkArraySetup.length; i++) {
                    var current = window.D4LinkArraySetup[i];
                    makeAdLinkToProduct(current.elementId, current.productLink);
                }
                window.D4LinkArraySetup = []; //clearing it
                //linker virker ikke enda etter at nye elementer er lagt til
                //må debugge dette!

            }
        },
            5);
    };

    self.stats = [];

    this.registerStatistics = function (data) {
        if (data != undefined && data != null && !self.InitialLoadDone && (data.PopupRule == undefined || data.PopupRule <= 0)) { // && (data.SlideShowModel == undefined || data.SlideShowModel == null)) {
            if (self.CheckStatistics) {
                self.stats.push(data);
            }
        }
    };

    this.postStatistics = function () {
        try {
            if (self.stats != null && self.stats.length > 0) {
                var data = self.stats[0];
                var stats = {
                    DeviceSize: self.deviceSize,
                    NodeId: data.NodeId,
                    FieldIds: [],
                    Note: 'FieldsShownBeforeScroll'
                };
                for (var i = 0; i < self.stats.length; i++) {
                    stats.FieldIds.push(self.stats[i].FieldId);
                }
                self.service.PostAction("PostStatistics", stats).done(function () {
                    self.stats = [];
                });
            }
        }
        catch (e) { }
    };

    this.updateContentForSpecificFields = function (fieldRecognizer) {
        self.addRemainingFieldsToDom();
        self.reloadFromCallbackActive = true;
        self.updateFields(fieldRecognizer, "#AttributeListBox", false, true, false, 0, 0);
    };

    this.updateContentForPreloadedFields = function(fieldRecognizer, includeMegaMenuChildren){
        var recognizer = "div.ajax-field.loaded";
        if(fieldRecognizer !== undefined){
            recognizer = fieldRecognizer;
        }
        var preloadedFields = jQuery(recognizer);

        var fieldItems  = [];
        for(var j = 0; j< preloadedFields.length; j++){
            var field = jQuery( preloadedFields[j]);
            var fieldItem = {
                FieldId: field.data('field-id'),
                NodeId: field.data('node-id'),
                UniqueId: field[0].id
            };
            if (!includeMegaMenuChildren  && $(field).parents(".dropdown-submenu").length > 0)
                continue;//fields within mega menu are not to be loaded here, will be done when menu is shown.

            var preloadedElements = field.find("div.WebPubElement");
            var eItems = [];
            for(var i = 0; i < preloadedElements.length; i++){
                var element =jQuery( preloadedElements[i]);
                var eItem =  {
                    ElementId: element.data('elementid'),
                    Plid: element.data('plid')
                };
                eItems.push(eItem);
            }
            fieldItem.Elements = eItems;
            fieldItems.push(fieldItem);
        }

        if(fieldItems.length > 0){
            self.service.PostAction("GetPriceDataAsync", {Fields : fieldItems}).done( function (result) {
                if(result && result.Success){
                    var fields = result.Fields;

                    for(var i = 0; i < fields.length; i++){
                        var fData = fields[i];
                        var field = jQuery("#" + fData.UniqueId);
                        self.ensureCorrectImageSizesForField(field, fData);
                        self.applyCustomerSpecifics(field, fData);
                    }
                }
            });
        }
        
        self.showSearchSynonymText();

    }
    this.showSearchSynonymText = function () {
        if (jQuery(".search-synonyms").length > 0)
            return;

        var alteredSearchResultArea = jQuery(".web-pub-field[data-search-synonyms]");
        if (alteredSearchResultArea.length <= 0)
            return;

        var alteredText = alteredSearchResultArea.data("search-synonyms");
        if (alteredText !== undefined && alteredText !== null && alteredText.length <= 0)
            return;
        var newContent = "<H3 class='search-synonyms'>" + alteredText + "</H3>";
        alteredSearchResultArea.first().before(newContent);
        
    };
    this.applyModelRegisterExtraData = function (targetfield, fieldId) {
        if (targetfield === null || targetfield === undefined || fieldId === undefined || fieldId === null)
            return;
        if (self.placeholderDict === null || self.placeholderDict === undefined)
            return;
        var extraData = self.placeholderDict["extraData" + fieldId]
        if (extraData === undefined || extraData === null)
            return;

        for (var i = 0; i < extraData.length; i++) {
            var item = extraData[i];
            if (item === null || item.Plid <= 0)
                continue;

            if (item.AccessoriesGroup !== null) {
                let accessoriesgroup = $(".mod-reg-ag.mode-reg-id-" + item.Plid);
                accessoriesgroup.html(item.AccessoriesGroup);
                accessoriesgroup.removeClass("hidden");
            }
            if (item.FreeText !== null) {
                let freeTextItem = $(".mod-reg-ft.mode-reg-id-" + item.Plid);
                freeTextItem.html(item.FreeText);
                freeTextItem.removeClass("hidden");
            }
        }

    };

    this.applyCustomerSpecifics = function (targetfield, data) {
        if (data === null)
            return;

        self.applyModelRegisterExtraData(targetfield, data.FieldId);

        if (data.PriceData === null || data.PriceData.length <= 0)
            return;

        var priceTags = jQuery(targetfield).find(".locate-prices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            jQuery(priceTags).filter("#adprice__" + data.FieldId + "_" + data.PriceData[i].ElementId).each(function (index, item) {
                var $item = jQuery(item);
                $item.html(data.PriceData[i].Price);
                if (data.PriceData[i].Discount !== null && data.PriceData[i].Discount.length > 0) {
                    $item.addClass("has-discount");
                }
                if(data.PriceData[i].NoBuy){
                    var myElement = jQuery(jQuery(item).parents("div.WebPubElement")[0]);
                    myElement.find("a.ad-buy-button").addClass("disabled");
                    myElement.find("a.list-buy-button").addClass("disabled");
                }
                else if(data.PriceData[i].UnlockBuy){
                    var myElement = jQuery(jQuery(item).parents("div.WebPubElement")[0]);
                    myElement.find("a.ad-buy-button").removeClass("disabled");
                    myElement.find("a.list-buy-button").removeClass("disabled");
                }
            });

            if(data.PriceData[i].BidName !== null && data.PriceData[i].BidName.length > 0){
                var countDown = jQuery(".WebPubElement[data-elementid="+data.PriceData[i].ElementId + "] .sale-countdown");
                countDown.data('bid-expires',data.PriceData[i].BidExpires);
            }
//todo: skriv om slik at vi her først legger expire-tiden inn som et data-element på elemented, og så start en timer som går igjennom alle elementene en etter en. Vi ønsker ikke
//å ha mer enn 1 timer pr felt i hvertfall


            var timer = setInterval(function() {

                var now = new Date().getTime();
                jQuery(".WebPubElement .sale-countdown").each(function(index){
                    var salesCountdown = jQuery(this);
                    var endDate = new Date(salesCountdown.data("bid-expires")).getTime();
                    var t = endDate - now;

                    if (t >= 0) {
                        var days = Math.floor(t / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                        var secs = Math.floor((t % (1000 * 60)) / 1000);

                        jQuery(salesCountdown).find(".timer-days").html( days +
                        "<span class='label'>DAY(S)</span>");

                        jQuery(salesCountdown).find(".timer-hours").html(("0"+hours).slice(-2) +
                        "<span class='label'>HR(S)</span>");

                        jQuery(salesCountdown).find(".timer-mins").html(("0"+mins).slice(-2) +
                        "<span class='label'>MIN(S)</span>");

                        jQuery(salesCountdown).find(".timer-secs").html(("0"+secs).slice(-2) +
                        "<span class='label'>SEC(S)</span>");
                    } else {
//the price for this product should now be invalid
                        salesCountdown.removeClass("sale-countdown");
                        salesCountdown.data("bid-expires","");
                        salesCountdown.html('');
                    }
                });

            }, 1000);
        }


        var discountTags = jQuery(targetfield).find(".locate-yousave-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line != null && line.Discount != null && line.Discount.length > 0) {
                jQuery(discountTags).filter("#adyousave__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.Discount);
                    jItem.addClass("show");
                });
            }
        }

        var oldPriceTags = jQuery(targetfield).find(".locate-oldprices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.OldPrice != null && line.OldPrice.length > 0) {
                jQuery(oldPriceTags).filter("#adoldprice__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.OldPrice);
                    jItem.addClass("show");
                    jItem.prev().addClass("show");//must show the prefix also
                });
            }
        }

        var rrpPriceTags = jQuery(targetfield).find(".locate-rrpprices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.Rrp != null && line.Rrp.length > 0) {
                jQuery(rrpPriceTags).filter("#adrrpprice__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.Rrp);
                    jItem.addClass("show");
                });
            }
        }


        var nordeaprices = jQuery(targetfield).find(".locate-nordeaprices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.FinancingTxt != null && line.FinancingTxt.length > 0) {
                jQuery(nordeaprices).filter("#adnordeaprice__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.FinancingTxt);
                    jItem.addClass("show");
                });
            }
        }

        var backorders = jQuery(targetfield).find(".locate-adbackorder-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.BackorderTxt != null && line.BackorderTxt.length > 0) {
                jQuery(backorders).filter("#adbackorder__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.BackorderTxt);
                    jItem.addClass("show");
                });
            }
        }
        
    };

    this.ensureCorrectImageSizesForField = function (targetfield, data) {
        var $field = jQuery(targetfield);
        var images = $field.find("img.d4-prod-thumb");
        var lazyImages = $field.find("img.d4-lazy-thumb");

        var loadSlideshowForProdThumb = images.length > 0 && lazyImages.length <= 0; //only start slideshow after loading images for d4-prod-thumb if we don't have d4-lazy-thumbs also
        var loadSlideshowForLazyThumb = lazyImages.length > 0 || !loadSlideshowForProdThumb; //start slideshow if we didn't start it for d4-prod-thumb or we have d4-lazy-thumb

        self.ensureCorrectImageSizes(images, $field, data, false, loadSlideshowForProdThumb);
        self.ensureCorrectImageSizes(lazyImages, $field, data, true, loadSlideshowForLazyThumb);
        EnableLinksForArticlesWithBackground();
        InitializeAnimationsInArticleWithBackground();
    };


    this.updateFieldContent = function (fieldData) {
        if (!fieldData.Success)
            return;

        try {        
            self.fieldUpdateInProgress = true;
        
            self.PagingMode = fieldData.PagingMode;

            if (!self.IntersectionObserveAllowed)
                fieldData.AllowDeferred = false;

            if (fieldData.AllowDeferred && fieldData.PopupRule <= 0) {
                for (var i = 0; i < fieldData.Data.length; i++) {
                    var data = fieldData.Data[i];
                    data.added = false;
                }
            }

            if (fieldData.PopupRule <= 0 && (!fieldData.AllowDeferred || (fieldData.PagesRemaining > 0))) {
                
                self.addRemainingFieldsToDom();//temporary fix for fields that wouldn't load. Needed when the fields added in the loop below adds content that sits between the fields held in memory and themselves.

                for (var i = 0; i < fieldData.Data.length; i++) {
                    var data = fieldData.Data[i];
                    data.targetField = $('#' + data.ClientId);
                    self.addFieldContentToPage(fieldData, data.targetField, data);
                }
            }
            else if (fieldData.PopupRule > 0) {
                if (fieldData.Data.length > 0) {
                    var isSlideOver = fieldData.PopupPch === "#slideover-placeholder";

                    var lightboxContainer = $(fieldData.PopupPch);
                    if (lightboxContainer != undefined &&
                        (
                            (!isSlideOver && !self.lightBoxIsOpen)
                        ||
                            (isSlideOver && !self.slideOverBoxIsOpen)
                        )

                    ) {

                        mcWeb.lightbox.movePopupFieldsToOriginalPlaceholder(lightboxContainer);
                        lightboxContainer.empty();
                        var dataFound = false;

                        for (var i = 0; i < fieldData.Data.length; i++) {
                            var data = fieldData.Data[i];
                            if (data.Response.length > 0) {
                                dataFound = true;
                                break;
                            }
                        }

                        if (dataFound) {
                            var popupParam = {
                                css: "d4-popup d4-popup-rule-" + fieldData.PopupRule + self.getCssClassesForLightbox(fieldData),
                                dataToApply: fieldData,
                                callback: function () { self.fillLightboxWithContent(fieldData, lightboxContainer); self.initialiseAdLinks(); }
                            }
                            PubSub.publish(fieldData.PopupEvent, popupParam);
                            PubSub.publish(mcWeb.lightbox.events.contentChanged);
                            if (isSlideOver) {
                                PubSub.subscribe(mcWeb.slideOverBox.events.onHideLightbox, function () {
                                    self.slideOverBoxIsOpen = false;
                                });
                                self.slideOverBoxIsOpen = true;
                            }
                            else {
                                PubSub.subscribe(mcWeb.lightbox.events.onHideLightbox, function () {
                                    self.lightBoxIsOpen = false;
                                });
                                self.lightBoxIsOpen = true;
                            }
                            
                        }
                    }
                }
            }
            else {
                var skipAdd = self.fieldsReadyToLoad.length > 0;

                if (fieldData.Data.length > 0)
                    self.fieldsReadyToLoad.push(fieldData);

                if (!skipAdd) {
                    self.addNextFieldToDom();
                } else {
                    // Fix for EJ 158200, load all remaning fields if there is anything published on the left side
                    if (jQuery("#PageColumnLeft > .PublisherContainer .web-pub-field").length > 0) {
                        self.addRemainingFieldsToDom();
                    }
                }
            }

            if (fieldData.InitializeRoyalSlider) {
                InitializeRoyalSliderInAds();
            }
            if (fieldData.PagesRemaining > 0) {
                self.HasMorePages = true;
            } else if(data !== null && data !== undefined && data.IsProductListing){
                if (fieldData.PagingMode > 2) {
                    self.hidePaging();
                }
                else if (fieldData.PagingMode === 2 && fieldData.Data !== null && fieldData.Data != undefined && fieldData.Data[0] !== null && fieldData.Data[0] !== undefined && fieldData.Data[0].IsProductListing) {
                    self.hidePaging();
                }
            }

            //scrolling for paging within a field
            if (self.PagingMode >= 3 && !self.scrollEventIsRegistered && fieldData.PagesRemaining > 0) {
                self.scrollEventIsRegistered = true;
                jQuery(window).on("scroll", null, null, self.handleScroll);
            } else if (fieldData.PagesRemaining == 0 && self.PagingMode >= 3 && self.scrollEventIsRegistered && fieldData.Data.length > 0 && !fieldData.AllowDeferred) {
                //no more pages, let's remove the scroll registration
                jQuery(window).off("scroll", null, self.handleScroll);
                self.scrollEventIsRegistered = false;
            }

            var footerlist = document.querySelectorAll('.copyright-container');
            var footer = null;
            if (footerlist.length > 0)
                footer = footerlist[0];

            //scrolling that causes multiple fields to load
            if (footer != null) {
                self.addScrollObserver(fieldData, footer);
            }

            self.LoadingFromScroll = false;//re-enable auto-scrolling
            if (typeof lipscore !== 'undefined') {
                lipscore.initWidgets()
            }
        
            if (fieldData.Data.some(x => x.IsProductListing)) {
                self.handleScroll();
            }
            
        } catch (e) {
            console.log(e);
        }
        finally {
            self.fieldUpdateInProgress = false;
        }
    };

    self.getCssClassesForLightbox = function (fieldData) {
        var classesForFields = "";
        for (var i = 0; i < fieldData.Data.length; i++) {
            var data = fieldData.Data[i];
            if (data.Response.length <= 0) {
                continue;
            }
            classesForFields += " popupfield-" + data.FieldId;
        }
        return classesForFields;
    };

    self.fillLightboxWithContent = function (fieldData, lightboxContainer) {
        for (var i = 0; i < fieldData.Data.length; i++) {
            var data = fieldData.Data[i];
            if (data.Response.length <= 0) {
                continue;
            }
            data.targetField = $('#' + data.ClientId);
            if (data.targetField != null && data.targetField != undefined) {
                //add a placeholder where the field was found, so we can put it back when the lightbox is closed
                data.targetField.hide();
                $(data.targetField).html(data.Response);                
                var fields = $(data.targetField).closest(".web-pub-field.container, .web-pub-field.container-fluid");

                fields.after("<div style='display:none;' data-placeholder-id='" + fields[0].id + "'/>");
                var fieldPopupHtml = $(fields).detach();
                lightboxContainer.append(fieldPopupHtml);
                data.targetField.show();
                if (data.LoadFilter) {
                    this.updateFilterContent(data);

                }
                self.ensureCorrectImageSizesForField(data.targetField, data);
                self.applyCustomerSpecifics(data.targetField, data);
                if (fieldData.InitializeRoyalSlider)
                    InitializeRoyalSliderInAds("#" + data.ClientId + " .variant-slider");
            }
        }
    };

    self.handleScroll = function (event) {
        if (self.HasMorePages && !self.LoadingFromScroll && !self.fieldUpdateInProgress && (self.PagingMode == 3 || self.AutoScrolledPages < 3)) {
            self.doScrollHandling();
        }
        else if (self.HasMorePages && !self.LoadingFromScroll && self.fieldUpdateInProgress && (self.PagingMode == 3 || self.AutoScrolledPages < 3)) {
            //need to delay the scroll handling and try again, in case we complete the ongoing field update and it subsequently needs to handle the scroll
            setTimeout(function () {
                if (!self.HasMorePages || self.LoadingFromScroll || self.fieldUpdateInProgress || self.PagingMode  < 3 || self.AutoScrolledPages >= 3)
                    return;

                self.doScrollHandling();
            }, 500);
        }
    };

    self.doScrollHandling = function () {
        var scrollerContainer = jQuery(".field-paging-container");
        if (scrollerContainer.length > 0 && McUtils.isElementInView(scrollerContainer)) {
            var scrollerTag = jQuery(".field-paging-next:visible");
            if (scrollerTag != null && scrollerTag.length > 0 && McUtils.isElementInView(scrollerTag)) {
                self.LoadingFromScroll = true; //pause event-checking on scrolling
                self.AutoScrolledPages = self.AutoScrolledPages + 1;
                self.scrollToNextPage(scrollerTag[0]);
            }
        }
    };

    self.resetAutoScrolledPages = function () {
        self.AutoScrolledPages = 0;
    };

    self.onHeaderIntersection = function (entries) {
        if (!self.InitialLoadDone)
            return;

        var entry = null;
        if (entries.length > 0)
            entry = entries[0];
        else {
            return;
        }

        // Are we in viewport?
        if (entry.intersectionRatio > 0 && self.CurrentPageNumber > 1) {

            var previousElements = jQuery(".field-paging-prev");
            var prevElement = previousElements[0];

            self.scrollToPrevPage(prevElement);

            if (self.CurrentPageNumber <= 1) {
                // No more unloaded areas/fields, so stop observing
                self.headerObserver.unobserve(entry.target);
                self.scrollEventPrevRegistered = false;
                self.headerObserver = null;
            }

        }
    };

    self.onIntersection = function (entries) {

        if (!self.InitialLoadDone)
            return;

        var entry = null;
        if (entries.length > 0)
            entry = entries[0];
        else {
            return;
        }

        // Are we in viewport?
        if (entry.intersectionRatio > 0) {
            if (!self.HasMorePages) {
                self.addNextFieldToDom();
            }

            if (jQuery(".ajax-field.not-loaded").length == 0) {
                // No more unloaded areas/fields, so stop observing
                self.footerObserver.unobserve(entry.target);
                self.scrollEventFooterIsRegistered = false;
                self.footerObserver = null;
            }

        }

    };

    self.onFieldIntersection = function (entries) {

        var entry = null;
        if (entries.length > 0)
            entry = entries[0];
        else {
            return;
        }

        // Are we in viewport?
        if (entry.intersectionRatio > 0) {
            if (!self.HasMorePages || self.PagingMode <= 2) {
                // Stop observing
                self.fieldScrollObserver.unobserve(entry.target);
                self.scrollEventFieldIsRegistered = false;
                self.fieldScrollObserver = null;

                self.addNextFieldToDom();
            }
        }
    };

    self.addRemainingFieldsToDom = function(){
       self.LoadingFromScroll = true; //pause event-checking on scrolling
        
        if(self.HasMoreFieldsToLoad()) {
            while (self.HasMoreFieldsToLoad()) {
                var myFieldData = self.fieldsReadyToLoad[0];
                var myInnerData = myFieldData.Data.shift();
                if (myFieldData.Data.length == 0) {
                    self.fieldsReadyToLoad.shift();
                }
                myInnerData.targetField = $('#' + myInnerData.ClientId);
                self.addFieldContentToPage(myFieldData, myInnerData.targetField, myInnerData);
            }
        }
        self.LoadingFromScroll = false;
    };

    self.addNextFieldToDom = function (noloop) {
        self.LoadingFromScroll = true;//pause event-checking on scrolling
        var fieldWasAdded = false;

        if(self.HasMoreFieldsToLoad()) {
            while (self.HasMoreFieldsToLoad()) {
                var myFieldData = self.fieldsReadyToLoad[0];
                var myInnerData = myFieldData.Data.shift();
                if (myFieldData.Data.length == 0) {
                    self.fieldsReadyToLoad.shift();
                }
                myInnerData.targetField = $('#' + myInnerData.ClientId);
                self.addFieldContentToPage(myFieldData, myInnerData.targetField, myInnerData);
                fieldWasAdded = true;

                if (self.bottomImageToLoad != null && self.bottomImageToLoad != undefined && self.bottomImageToLoad.length > 0) {
                    break;
                }
                var footerlist = document.querySelectorAll('.copyright-container');
                var footer = null;
                if (footerlist.length > 0)
                    footer = footerlist[0];

                //if(myFieldData != undefined) {
                //   self.addFieldContentToPage(myFieldData, myInnerData.targetField, myInnerData);
                //}
                if(myInnerData.Response !== null && myInnerData.Response.trim().length > 0) {
                    if (myInnerData.targetField.height() > 0 && !McUtils.isElementInView(myInnerData.targetField)) {

                        //find the next field below the line to observe
                        var fieldToObserve = self.findNextFieldToObserve(self.fieldsReadyToLoad);
                        if (fieldToObserve != null) {
                            var fieldElement = document.getElementById(fieldToObserve.ClientId);
                            self.addFieldScrollObserver(fieldElement);
                        }
                        //observe the footer to enable it to trigger adding more content
                        if (!McUtils.isElementInView(footer)) {
                            self.addScrollObserver(myFieldData, footer);
                            break;
                        }
                    }
                }
            }
        } else {
            self.bottomImageToLoad = null;
        }

        // Find all fields above the view
        if (!self.finishedLoadingFieldsAbove) {
            var myFieldData, myInnerData;
            ({ myFieldData, myInnerData } = self.popNextFieldAboveView());
            while (myFieldData !== null) {
                myInnerData.targetField = $('#' + myInnerData.ClientId);
                self.addFieldContentToPage(myFieldData, myInnerData.targetField, myInnerData);
                fieldWasAdded = true;
                ({ myFieldData, myInnerData } = self.popNextFieldAboveView());
            }
            self.finishedLoadingFieldsAbove = true;
        }
        

        if (fieldWasAdded) {
            startParaxify('.d4-article-parallax');
        }

        if (self.bottomImageToLoad != undefined &&
            self.bottomImageToLoad != null &&
            self.bottomImageToLoad.length > 0) {
            var currImg = self.bottomImageToLoad[0];
            
            if (currImg != null && currImg.src != undefined && currImg.src != null) {
                var currentImgSrc = self.bottomImageToLoad[0].src;
                var img = new Image();
                img.onload = self.onBottomImgLoaded(myFieldData);//Set up loading of next fields when this image is loaded
                img.onerror = self.onBottomImgLoaded(myFieldData);
                img.src = currentImgSrc;                
                return;
            }
            else if($(currImg).hasClass("inner-content")){
                var bg = $(currImg).css('background-image').trim();
                if(bg.length > 0 && bg !== "none"){
                    //set up loading after this background image has loaded
                    var currentImgSrc= bg.substring(5, bg.length - 2);
                    var img = new Image();
                    img.onload = self.onBottomImgLoaded(myFieldData);
                    img.onerror = self.onBottomImgLoaded(myFieldData);
                    img.src = currentImgSrc;
                    return;
                }
                
            }
            
            //we need to check for more visible fields right away. self.bottomImageToLoad cannot be used to delay the loading

        }

        if (!self.HasMoreFieldsToLoad()) {
            startParaxify('.d4-article-parallax');
        }

        self.LoadingFromScroll = false;//un-pause event-checking on scrolling
        // self.addScrollObserver(myFieldData);
    };

    self.onBottomImgLoaded = function(myFieldData){
        return function(){
            var footerlist = document.querySelectorAll('.copyright-container');
            var footer = null;
            if (footerlist.length > 0)
                footer = footerlist[0];

            var loadNextField = false;
            if (McUtils.isElementInView(footer)) {
                loadNextField = true;
            }

            var nextField = self.findNextVisibleField(self.fieldsReadyToLoad);
            if (nextField !== null) {
                loadNextField = true;
            } else {
                var fieldToObserve = self.findNextFieldToObserve(self.fieldsReadyToLoad);
                if (fieldToObserve != null) {
                    var fieldElement = document.getElementById(fieldToObserve.ClientId);
                    self.addFieldScrollObserver(fieldElement);
                }
            }

            if (!loadNextField) {
                if (self.CheckStatistics) {
                    //we have now added the last dynamic field before scrolling, so we log which ones it is so we can learn which fields to load statically later to optimize the page
                    self.postStatistics();
                    self.CheckStatistics = false;
                }

                self.addScrollObserver(myFieldData, footer);
                return;
            }

            self.addNextFieldToDom(true);
        }
    };

    self.popNextFieldAboveView = function () {

        for (var i = 0; i < self.fieldsReadyToLoad.length; i++) {
            var fieldReadyToLoad = self.fieldsReadyToLoad[i];

            for (var n = 0; n < fieldReadyToLoad.Data.length; n++) {
                var data = fieldReadyToLoad.Data[n];
                var element = document.getElementById(data.ClientId);

                if (element !== null) {
                    if (McUtils.isElementAboveView(element)) {
                        fieldReadyToLoad.Data.splice(n, 1);
                        if(fieldReadyToLoad.Data.length === 0)
                            self.fieldsReadyToLoad.splice(i, 1);


                        return { myFieldData: fieldReadyToLoad, myInnerData: data };
                    }
                }
            }
        }

        return { myFieldData: null, myInnerData: null };
    }

    self.HasMoreFieldsToLoad = function () {
        if (self.fieldsReadyToLoad.length == 0 || self.fieldsReadyToLoad[0].Data.length == 0)
            return false;
        else return true;
    };

    this.findNextVisibleField = function () {

        for (var i = 0; i < self.fieldsReadyToLoad.length; i++) {
            var fieldReadyToLoad = self.fieldsReadyToLoad[i];

            for (var n = 0; n < fieldReadyToLoad.Data.length; n++) {
                var data = fieldReadyToLoad.Data[n];
                var element = document.getElementById(data.ClientId);

                if (element !== null) {
                    if (McUtils.isElementInView(element))
                        return data;
                }
            }
        }

        return null;
    };

    this.findNextFieldToObserve = function () {

        for (var i = 0; i < self.fieldsReadyToLoad.length; i++) {
            var fieldReadyToLoad = self.fieldsReadyToLoad[i];

            for (var n = 0; n < fieldReadyToLoad.Data.length; n++) {
                var data = fieldReadyToLoad.Data[n];
                var element = document.getElementById(data.ClientId);

                if (element !== null) {
                    if (McUtils.isElementBelowView(element))
                        return data;
                }
            }
        }

        return null;
    };

    this.addScrollObserver = function (fieldData, footer) {
        if (!self.scrollEventFooterIsRegistered && (self.HasMorePages || self.HasMoreFieldsToLoad())) {
            self.scrollEventFooterIsRegistered = true;
            var config = {
                // If the image gets within 50px in the Y axis, start the download.
                rootMargin: '0px 0px',
                threshold: 0.01
            };

            // The observer for the images on the page
            self.footerObserver = new IntersectionObserver(self.onIntersection, config);

            self.footerObserver.observe(footer);
        }
    };

    this.addFieldScrollObserver = function (field) {
        if (!self.scrollEventFieldIsRegistered) {
            self.scrollEventFieldIsRegistered = true;
            var config = {
                // If the image gets within 50px in the Y axis, start the download.
                rootMargin: '0px 0px',
                threshold: 0.01
            };

            // The observer for the images on the page
            self.fieldScrollObserver = new IntersectionObserver(self.onFieldIntersection, config);

            self.fieldScrollObserver.observe(field);
        }
    };



    this.toggleAttributeValues = function (target) {
        var container = $(target).parent();
        $(container).toggleClass('show-extra-values');
    };

    this.toggleIconAttributeValues = function (target) {
        $('#AttributeListBox .collapse.event-not-ready').on('shown.bs.collapse', function () {
            $(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");            
        }).on('hidden.bs.collapse', function () {
            $(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
        });
        $('#AttributeListBox .collapse').removeClass("event-not-ready");
    };

    this.searchAtttributes = function (target) {
        $(target).siblings().removeClass('search-hidden');

        const searchQuery = target.value.toLowerCase();

        if (searchQuery.length < 1) {
            $(target).parent().removeClass('search-show-extra-values');
            $(target).siblings('a').removeClass('toggle-hidden');
            $(target).siblings('a').show();
            return;
        }

        $(target).parent().addClass('search-show-extra-values');
        $(target).siblings('a').addClass('toggle-hidden');

        const elements = $(target).siblings('.attribute-value').find('a');


        elements.filter(function (index) {
            const title = $(this).attr('title').toLowerCase();
            return !title.includes(searchQuery) && !$(this).hasClass('selected');
        }).parent().addClass('search-hidden');
    }

    this.updateFilterContent = function (filterData) {
        var secondaryFilterId = "secondary-filter-" + filterData.ClientId;

        if (filterData != null && filterData.FilterResponse != null && filterData.FilterResponse.Success) {
            if (filterData.FilterResponse.ClientId == "secondary-filter") {
                //this must be added by us right before the field we're updating with the filter
                jQuery("#" + secondaryFilterId).remove();

            }
            var targetKey = filterData.FilterResponse.ClientId;

            if (targetKey === null)
                return; //nothing to do

            if (targetKey.indexOf("#") < 0) {
                targetKey = "#" + targetKey;
            }
            var targetDiv = $(targetKey);
            if (targetDiv !== undefined && targetDiv.length > 0) {
                if (filterData.FilterResponse.Response.length > 0) {
                    //keep old height of filter if we had one already...
                    var $targetDiv = $(targetDiv);
                    var oldHeight = $targetDiv.height();
                    if ($targetDiv.children().length < 0)
                        oldHeight = 0;
                    else {
                        $targetDiv.css("height", oldHeight + "px");
                    }
                    $targetDiv.html(filterData.FilterResponse.Response);
                    $targetDiv.attr("data-target-field-client-id", filterData.ClientId);
                    self.loadAttributeFilterNotYetLoaded($targetDiv, filterData.ManufacturerId, true);

                } else {
                    var filterContainer = $(".container-2016 has-filters");
                    if (filterContainer !== null && filterContainer !== undefined) {
                        $(filterContainer).addClass("no-filters");
                        $(filterContainer).removeClass("has-filters");
                        self.unhideProductMenu();
                    }
                }
                if (!self.attributeFilterInitiated) {
                    mcWeb.attributeFilte.init(filterData.ShowCompressedFilter);
                    self.attributeFilterInitiated = true;
                }
            }
            else if (filterData.FilterResponse.ClientId == "secondary-filter") {
                targetDiv = $("#" + filterData.ClientId).closest(".web-pub-field")[0];
                $(targetDiv).before("<div class=\'secondary-filter' id=\'" + secondaryFilterId + "'></div>");
                $("#" + secondaryFilterId).html(filterData.FilterResponse.Response);
            }
        }
    };
    this.urlParamsFilterIsValidFor = null;

    this.shouldDelayLoadingFilter = function (attributeFilterParent) {
        if (attributeFilterParent == undefined || attributeFilterParent == null || attributeFilterParent.length <= 0 || attributeFilterParent[0].id !== "AttributeListBox") {
            return false;
        }

        return jQuery("#filter-toggle").length > 0 && jQuery("#filter-toggle").data("expandlevel").length > 0 && !jQuery("#filter-content").is(":visible");
    };

    this.delayLoadFilterParent = null;
    this.delayLoadFilterManufacturerId = 0;
    this.delayLoadFilterLoaded = false;
    this.storeFilterLoadCall = function (parent, manufacturerId) {
        self.delayLoadFilterParent = parent;
        self.delayLoadFilterManufacturerId = manufacturerId;
        PubSub.subscribe(AjaxRenderingEngineEventTypes.onFilterLoadRequested, function (message, data) {
            if (self.delayLoadFilterLoaded)
                return;//loaded already

            self.loadAttributeFilterNotYetLoaded(self.delayLoadFilterParent, self.delayLoadFilterManufacturerId, false);
        });
    };

    this.loadAttributeFilterNotYetLoaded = function (attributeFilterParent, manufacturerid, allowDelay) {
        if (allowDelay && self.shouldDelayLoadingFilter(attributeFilterParent)) {
            // delay loading filter until the user clicks the show filter button
            self.delayLoadFilterLoaded = false;//allow filter loading
            self.storeFilterLoadCall(attributeFilterParent, manufacturerid);
            return;
        }
        self.delayLoadFilterLoaded = true;//filter loading now requested, prevent additional calls

        $(attributeFilterParent).addClass("attribute-spinner");
        var queryFilterParam = self.getParameterByName("Filter");
        var queryMinParam = self.getParameterByName("Min");
        var queryMaxParam = self.getParameterByName("Max");
        var pageIndex = self.getParameterByName("pageID");
        var listingFields = jQuery(".ajax-field[data-field-is-productlist='1']");
        var cacheKey = '';
        if (self.urlParamsFilterIsValidFor === null || self.urlParamsFilterIsValidFor === queryFilterParam) {
            self.urlParamsFilterIsValidFor = queryFilterParam;
            if (listingFields.length > 0) {
                cacheKey = jQuery(listingFields[0]).attr("data-cachekey");
            }
        }


        var fieldRequestFilter = {
            NodeId: jQuery('#hidden-nodeid').text(),
            Url: document.location.pathname + document.location.search,
            Filter: queryFilterParam,
            MinPrice: queryMinParam,
            MaxPrice: queryMaxParam,
            SearchString: self.getParameterByName("q"),
            ClientId: '',
            PageIndex: pageIndex,
            FilterCacheKey: cacheKey,
            ManufacturerId: manufacturerid
        }


        var notProcessedFields = attributeFilterParent.find(".not-processed");

        if (notProcessedFields.length > 0) {
            var cachingparams = {
                RequestFilter: fieldRequestFilter,
                FieldId: $(notProcessedFields[0]).data("fieldid")
            };

            self.service.PostAction("CacheProductFilter", cachingparams).done(function (result) {
                if (result.Success)
                    fieldRequestFilter.FilterCacheKey = result.CacheKey;

                self.checkAttributeVisibility(attributeFilterParent, fieldRequestFilter);
            });
        } else if (allowDelay) {
            //no filters to show, only the menu, must show it immediately
            $(attributeFilterParent).removeClass("attribute-spinner");
            attributeFilterParent.css("height", "");
            self.unhideProductMenu();
        }

    };
    this.checkAttributeVisibility = function (attributeFilterParent, fieldRequestFilter) {
        var attributesToProcess = attributeFilterParent.find(".not-processed");
        var itemsToProcess = attributesToProcess.length;
        var itemsProcessed = 0;
        attributeFilterParent.find(".not-processed").each(function (index, item) {
            var filterItems = [];
            var itemParam = {
                AttributeId: $(item).data("aid"),
                NodeId: $(item).data("nodeid"),
                FieldId: $(item).data("fieldid"),
                Identifier: item.id
            }
            filterItems.push(itemParam);

            self.service.PostAction("CheckAttributeVisibility", { FilterItems: filterItems, RequestFilter: fieldRequestFilter }).done(function (result) {
                if (!!result && result.Success) {
                    for (var i = 0; i < result.Items.length; i++) {
                        var item = result.Items[i];
                        if (item.Visible) {
                            $('#' + item.Identifier).replaceWith(item.RenderedData);
                        }
                        else {
                            $('#' + item.Identifier).replaceWith('');
                        }

                        var nextItem = $('#' + item.Identifier).next();
                        if (nextItem.length > 0 && !nextItem.hasClass("not-process")) {
                            nextItem.find("div.AttributeBox").removeClass("hidden");
                        }
                    }
                }
                $(attributeFilterParent).removeClass("attribute-spinner");

                itemsProcessed++;

                if (itemsProcessed >= itemsToProcess) { //we're done, let's show the menu
                    attributeFilterParent.css("height", "");
                    self.unhideProductMenu();
                }
            });
        });
    };

    this.unhideProductMenu = function () {
        var menu = jQuery("div.ProductMenu");
        if (menu.length > 0) {
            menu.removeClass("hidden");
        }
    };
    
    this.selectLayoutClick = function (event) {

        var dummyBarn = jQuery(".dummy-element *");

        dummyBarn.prop("onclick", null);
        dummyBarn.removeAttr('href');
        dummyBarn.off();

        event.stopImmediatePropagation();

        mcWeb.publisherAdminMenu.callLayoutAction("Select", event);
        return false;
    };

    this.cancelBubble = function (e) {
        var evt = e ? e : window.event;
        if (evt.stopPropagation) evt.stopPropagation();
        if (evt.cancelBubble != null) evt.cancelBubble = true;
    };

  

    self.updateVariantMatrixField = function (data) {
        var listingField = jQuery('.matrix-popup .ajax-field[data-field-products-set-clientside="1"]');
        if (listingField != null && listingField.length > 0) {
            if (data != undefined && data != null && data.variantMatrix != undefined && data.variantMatrix != null
                && data.variantMatrix.VariantChilds != undefined && data.variantMatrix.VariantChilds != null) {
                var children = "";

                for (var i = 0; i < data.variantMatrix.VariantChilds.length; i++) {
                    children += data.variantMatrix.VariantChilds[i].ProduktLagerId + ",";
                }
                listingField.attr('data-Plids', children);
                listingField.attr('data-variant-sorting','true');
            }
        }
    };
})(jQuery);

if(document.observe === undefined){
    document.observe = function (name, myfunction) {
        if(name === "dom:loaded")
            jQuery.ready(myfunction);
    // Override document.observe to not give errors from Gaia
    };
}


jQuery.fn.outerHTML = function () {
    return jQuery('<div />').append(this.eq(0).clone()).html();
};

window.mcWeb.ajaxRenderEngine = AjaxRenderingEngine;
window.mcWeb.ajaxRenderEngine.events = AjaxRenderingEngineEventTypes;